@charset "UTF-8";
/*-----------------------------------------------------------------------------------

    Template Name: Torun - Technology IT Solutions & Services HTML5 Template
    Description: Technology IT Solutions & Services HTML5 Template
    Author: BDevs
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

01. Theme default CSS
2. header
3. about
4. slider
5. footer
6. service
7. team
8. cta
9. project
10. testimonial
11. contact
12. carrer
13. blog
14.shop

-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/
/* 1. Theme default css */
@font-face {
  font-family: "cerebri_sansbold";
  src: url("../fonts/cerebrisans-bold-webfont.html") format("woff2"), url("../fonts/cerebrisans-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "cerebri_sansmedium";
  src: url("../fonts/cerebrisans-medium-webfont.html") format("woff2"), url("../fonts/cerebrisans-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "cerebri_sansregular";
  src: url("../fonts/cerebrisans-regular-webfont.html") format("woff2"), url("../fonts/cerebrisans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "cerebri_sanssemibold";
  src: url("../fonts/cerebrisans-semibold-webfont.html") format("woff2"), url("../fonts/cerebrisans-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "cerebri_sansregular";
  font-style: normal;
  color: #696969;
}

.img, img {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.fix {
  overflow: hidden;
}

a,
.button {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: medium none;
}

button:focus, input:focus, input:focus, textarea, textarea:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "cerebri_sansbold";
  color: #333333;
  margin-top: 0px;
  font-style: normal;
  text-transform: normal;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 40px;
  font-weight: 500;
}

h2 {
  font-size: 35px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
}

p {
  font-size: 15px;
  font-weight: normal;
  line-height: 28px;
  color: #696969;
  margin-bottom: 15px;
}

hr {
  border-bottom: 1px solid #eceff8;
  border-top: 0 none;
  margin: 30px 0;
  padding: 0;
}

label {
  color: #7e7e7e;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

*::-moz-selection {
  background: #333333;
  color: #ffffff;
  text-shadow: none;
}

::-moz-selection {
  background: #333333;
  color: #ffffff;
  text-shadow: none;
}

::selection {
  background: #333333;
  color: #ffffff;
  text-shadow: none;
}

/*--
    - Input Placeholder
-----------------------------------------*/
*::-moz-placeholder {
  color: #333333;
  font-size: 14px;
  opacity: 1;
}

*::placeholder {
  color: #333333;
  font-size: 14px;
  opacity: 1;
}

/*--
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

/*--
    - Background color
-----------------------------------------*/
.grey-bg {
  background: #f8f8f8;
}

.grey-bg-2 {
  background: #f4f9fc;
}

.white-bg {
  background: #ffffff;
}

.black-bg {
  background: #333333;
}

.theme-bg {
  background: #096bd8;
}

.theme-bg {
  background: #096bd8;
}

.footer-bg {
  background: #091b29;
}

/*--
    - color
-----------------------------------------*/
.white-color {
  color: #ffffff;
}

.black-color {
  color: #333333;
}

.theme-color {
  color: #096bd8;
}

/*--
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

/*-- Margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

.mb-205 {
  margin-bottom: 205px;
}

.mb-210 {
  margin-bottom: 210px;
}

.mb-215 {
  margin-bottom: 215px;
}

.mb-220 {
  margin-bottom: 220px;
}

.mb-225 {
  margin-bottom: 225px;
}

.mb-230 {
  margin-bottom: 230px;
}

.mb-235 {
  margin-bottom: 235px;
}

.mb-240 {
  margin-bottom: 240px;
}

.mb-245 {
  margin-bottom: 245px;
}

.mb-250 {
  margin-bottom: 250px;
}

.mb-255 {
  margin-bottom: 255px;
}

.mb-260 {
  margin-bottom: 260px;
}

.mb-265 {
  margin-bottom: 265px;
}

.mb-270 {
  margin-bottom: 270px;
}

.mb-275 {
  margin-bottom: 275px;
}

.mb-280 {
  margin-bottom: 280px;
}

.mb-285 {
  margin-bottom: 285px;
}

.mb-290 {
  margin-bottom: 290px;
}

.mb-295 {
  margin-bottom: 295px;
}

.mb-300 {
  margin-bottom: 300px;
}

/*-- Margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-155 {
  margin-left: 155px;
}

.ml-160 {
  margin-left: 160px;
}

.ml-165 {
  margin-left: 165px;
}

.ml-170 {
  margin-left: 170px;
}

.ml-175 {
  margin-left: 175px;
}

.ml-180 {
  margin-left: 180px;
}

.ml-185 {
  margin-left: 185px;
}

.ml-190 {
  margin-left: 190px;
}

.ml-195 {
  margin-left: 195px;
}

.ml-200 {
  margin-left: 200px;
}

/*-- Margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-105 {
  margin-right: 105px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-135 {
  margin-right: 135px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-145 {
  margin-right: 145px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-155 {
  margin-right: 155px;
}

.mr-160 {
  margin-right: 160px;
}

.mr-165 {
  margin-right: 165px;
}

.mr-170 {
  margin-right: 170px;
}

.mr-175 {
  margin-right: 175px;
}

.mr-180 {
  margin-right: 180px;
}

.mr-185 {
  margin-right: 185px;
}

.mr-190 {
  margin-right: 190px;
}

.mr-195 {
  margin-right: 195px;
}

.mr-200 {
  margin-right: 200px;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-205 {
  padding-top: 205px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-215 {
  padding-top: 215px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-225 {
  padding-top: 225px;
}

.pt-230 {
  padding-top: 230px;
}

.pt-235 {
  padding-top: 235px;
}

.pt-240 {
  padding-top: 240px;
}

.pt-245 {
  padding-top: 245px;
}

.pt-250 {
  padding-top: 250px;
}

.pt-255 {
  padding-top: 255px;
}

.pt-260 {
  padding-top: 260px;
}

.pt-265 {
  padding-top: 265px;
}

.pt-270 {
  padding-top: 270px;
}

.pt-275 {
  padding-top: 275px;
}

.pt-280 {
  padding-top: 280px;
}

.pt-285 {
  padding-top: 285px;
}

.pt-290 {
  padding-top: 290px;
}

.pt-295 {
  padding-top: 295px;
}

.pt-300 {
  padding-top: 300px;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pb-225 {
  padding-bottom: 225px;
}

.pb-230 {
  padding-bottom: 230px;
}

.pb-235 {
  padding-bottom: 235px;
}

.pb-240 {
  padding-bottom: 240px;
}

.pb-245 {
  padding-bottom: 245px;
}

.pb-250 {
  padding-bottom: 250px;
}

.pb-255 {
  padding-bottom: 255px;
}

.pb-260 {
  padding-bottom: 260px;
}

.pb-265 {
  padding-bottom: 265px;
}

.pb-270 {
  padding-bottom: 270px;
}

.pb-275 {
  padding-bottom: 275px;
}

.pb-280 {
  padding-bottom: 280px;
}

.pb-285 {
  padding-bottom: 285px;
}

.pb-290 {
  padding-bottom: 290px;
}

.pb-295 {
  padding-bottom: 295px;
}

.pb-300 {
  padding-bottom: 300px;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-85 {
  padding-left: 85px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-95 {
  padding-left: 95px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-105 {
  padding-left: 105px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-135 {
  padding-left: 135px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-145 {
  padding-left: 145px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-160 {
  padding-left: 160px;
}

.pl-165 {
  padding-left: 165px;
}

.pl-170 {
  padding-left: 170px;
}

.pl-175 {
  padding-left: 175px;
}

.pl-180 {
  padding-left: 180px;
}

.pl-185 {
  padding-left: 185px;
}

.pl-190 {
  padding-left: 190px;
}

.pl-195 {
  padding-left: 195px;
}

.pl-200 {
  padding-left: 200px;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-85 {
  padding-right: 85px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-95 {
  padding-right: 95px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-105 {
  padding-right: 105px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-135 {
  padding-right: 135px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-145 {
  padding-right: 145px;
}

.pr-150 {
  padding-right: 150px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-160 {
  padding-right: 160px;
}

.pr-165 {
  padding-right: 165px;
}

.pr-170 {
  padding-right: 170px;
}

.pr-175 {
  padding-right: 175px;
}

.pr-180 {
  padding-right: 180px;
}

.pr-185 {
  padding-right: 185px;
}

.pr-190 {
  padding-right: 190px;
}

.pr-195 {
  padding-right: 195px;
}

.pr-200 {
  padding-right: 200px;
}

/* scrollUp */
#scrollUp {
  background: #096bd8;
  height: 35px;
  width: 35px;
  right: 50px;
  bottom: 77px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
  line-height: 32px;
}

#scrollUp:hover {
  background: #333;
}

/* bounce-animate */
.bounce-animate {
  animation-name: float-bob;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -moz-animation-name: float-bob;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@-webkit-keyframes float-bob {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 10s;
  animation-duration: 60s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
/*--
    - Overlay
------------------------------------------*/
[data-overlay] {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
[data-overlay]::before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
}

/*-- Overlay Color --*/
[data-overlay=light]::before {
  background-color: #ffffff;
}

[data-overlay=dark]::before {
  background-color: #333333;
}

/*-- Overlay Opacity --*/
[data-opacity="1"]::before {
  opacity: 0.1;
}

[data-opacity="2"]::before {
  opacity: 0.2;
}

[data-opacity="3"]::before {
  opacity: 0.3;
}

[data-opacity="4"]::before {
  opacity: 0.4;
}

[data-opacity="5"]::before {
  opacity: 0.5;
}

[data-opacity="6"]::before {
  opacity: 0.6;
}

[data-opacity="7"]::before {
  opacity: 0.7;
}

[data-opacity="8"]::before {
  opacity: 0.8;
}

[data-opacity="9"]::before {
  opacity: 0.9;
}

/* 2 header */
.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  transition: 0.4s;
}

.sticky {
  left: 0;
  margin: auto;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  z-index: 9999;
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  background: #fff;
  z-index: 999;
}

.menu-2.sticky {
  padding-left: 45px;
  padding-right: 45px;
}

.header-transparent .sticky {
  background: #1b2f3e;
}

.header-info span {
  color: #8f8f8f;
  font-size: 15px;
  font-weight: 500;
  font-family: "cerebri_sansmedium";
  margin-right: 34px;
}

.header-info span:last-child {
  margin-right: 0;
}

.header-info span i {
  font-size: 16px;
  color: #096bd8;
  margin-right: 3px;
}

.header-lang {
  padding: 15px 0 16px;
  display: inline-block;
  margin-left: 60px;
}

.lang-icon > a {
  font-size: 14px;
  color: #8f8f8f;
  font-family: "cerebri_sansmedium";
  margin-left: 6px;
}

.lang-icon i {
  font-size: 15px;
  position: relative;
  top: 2px;
  left: 2px;
}

.header-lang-list {
  position: absolute;
  width: 120px;
  opacity: 0;
  visibility: hidden;
  padding: 20px 15px;
  border-top: 3px solid #096bd8;
  transition: 0.4s;
  z-index: 999;
  background: #fff;
  padding-bottom: 5px;
  padding-top: 4px;
  top: 100%;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
  transition: 0.3s;
}

.header-lang:hover .header-lang-list {
  opacity: 1;
  visibility: visible;
}

.header-lang-list li {
  display: block;
  margin-bottom: 0;
  padding: 5px 0;
}
.header-lang-list li a {
  display: block;
  color: #647589;
  font-size: 13px;
}
.header-lang-list li a:hover {
  color: #096bd8;
}

.pos-rel {
  position: relative;
}

.header-icon {
  margin-top: 14px;
}
.header-icon a {
  font-size: 14px;
  color: #cbcbcb;
  margin-right: 10px;
  transition: 0.3s;
}
.header-icon a:hover {
  color: #096bd8;
}
.header-icon a:last-child {
  margin-right: 0;
}

.header-button {
  margin-top: 25px;
  margin-left: 40px;
}
.header-button a.btn {
  padding: 15px 30px;
}

.header-3-icon {
  margin-top: 21px;
}
.header-3-icon a {
  font-size: 14px;
  color: #ffffff;
  margin-right: 8px;
  transition: 0.3s;
  height: 40px;
  width: 40px;
  display: inline-block;
  border: 2px solid #838383;
  text-align: center;
  line-height: 35px;
  border-radius: 5px;
}
.header-3-icon a:hover {
  background: #096bd8;
  border-color: #096bd8;
}
.header-3-icon a:last-child {
  margin-right: 0;
}

.menu-2 .header-right {
  margin-top: 20px;
}

.header-2-icon a {
  height: 40px;
  width: 40px;
  display: inline-block;
  background: #efefef;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  transition: 0.3s;
  margin-right: 5px;
}
.header-2-icon a:hover {
  background: #096bd8;
  color: #ffffff;
}
.header-2-icon a:last-child {
  margin-right: 0;
}

.menu-2 .header-lang {
  padding: 20px 0 16px;
  margin-left: 0;
  margin-right: 50px;
}

.header-2-right {
  margin-top: 32px;
}

.header-cta-text span {
  font-family: "cerebri_sansmedium";
  color: #fff;
  font-size: 14px;
}

.header-cta-text span i {
  margin-right: 6px;
  color: #ffffff;
  font-size: 14px;
}

.header-cta-text p {
  color: #fff;
  font-family: "cerebri_sansmedium";
  font-size: 18px;
  margin-bottom: 0;
}

.header-2-button {
  margin-right: 58px;
}

.header-2-button a {
  -moz-user-select: none;
  border: 2px solid #ffffff;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 0;
  padding: 15px 28px;
  text-align: center;
  text-transform: uppercase;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 5px;
  position: relative;
  font-family: "cerebri_sansbold";
  color: #fff;
}

.header-2-button a i {
  padding-left: 5px;
}

.header-2-button a:hover {
  background: #fff;
  color: #096bd8;
}

.menu-4 .lang-icon > a {
  color: #fff;
}

.menu-4 .header-2-icon a {
  background: #fff;
  color: #a9a9a9;
}

.menu-4 .header-2-icon a:hover {
  background: #096bd8;
  color: #fff;
}

.menu-4 {
  border-bottom: 2px solid #2d2d2d;
}

.header-area {
  border-bottom: 1px solid #5f6b6e;
  padding-top: 21px;
  padding-bottom: 28px;
}

.header-top-cta-text {
  margin-right: 74px;
}
.header-top-cta-text:last-child {
  margin-right: 0;
}

.header-top-right {
  margin-left: 74px;
}

.header-top-icon {
  float: left;
  margin-right: 15px;
  margin-top: 14px;
}
.header-top-icon i {
  color: #ffffff;
  font-size: 30px;
}

.header-top-text {
  overflow: hidden;
}
.header-top-text p {
  font-size: 14px;
  font-family: "cerebri_sansmedium";
  color: #ffffff;
  margin-bottom: 0;
  line-height: 26px;
}
.header-top-text span {
  font-size: 18px;
  color: #ffffff;
  font-family: "cerebri_sansbold";
}

/* menu */
.main-menu nav > ul > li {
  display: inline-block;
  position: relative;
  margin-left: 28px;
}

.main-menu nav > ul > li:first-child {
  margin-left: 0;
}

.main-menu nav > ul > li > a {
  color: #333333;
  display: block;
  font-size: 16px;
  padding: 43px 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background: transparent;
  line-height: 1;
  position: relative;
  text-transform: capitalize;
  font-family: "cerebri_sanssemibold";
}

.main-menu nav > ul > li > a i {
  font-size: 14px;
  position: relative;
  top: 1px;
  left: 4px;
  color: #8f8f8f;
}

.main-menu nav > ul > li:hover > a, .main-menu nav > ul > li.active > a {
  color: #096bd8;
}

.main-menu > nav > ul > li > a::before {
  background: #096bd8;
  content: "";
  height: 3px;
  position: absolute;
  bottom: 0;
  width: 0;
  transition: 0.5s;
}

.main-menu > nav > ul > li:hover > a::before, .main-menu > nav > ul > li.active > a::before {
  width: 100%;
}

.menu-4 .main-menu nav > ul > li > a {
  color: #ffffff;
}

.menu-4 .main-menu nav > ul > li > a i {
  color: #ffffff;
}

.menu-5 .main-menu nav > ul > li > a {
  color: #ffffff;
  padding: 30px 0;
}

.menu-2 .main-menu > nav > ul > li > a::before {
  display: none;
}

.menu-3 .main-menu > nav > ul > li > a::before {
  display: none;
}

.menu-5 .main-menu > nav > ul > li > a::before {
  display: none;
}

/* sub-menu */
.main-menu nav > ul > li .sub-menu {
  background: #ffffff none repeat scroll 0 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
  left: 0;
  opacity: 0;
  position: absolute;
  top: 120%;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 220px;
  z-index: 9;
  border-bottom: 3px solid #096bd8;
  text-align: left;
}

.main-menu nav > ul > li:hover .sub-menu {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.main-menu nav > ul > li .sub-menu li {
  display: block;
}

.main-menu nav > ul > li .sub-menu li a {
  color: #666666;
  font-size: 14px;
  font-family: "cerebri_sansregular";
  margin: 0;
  padding: 13px 20px;
  text-transform: capitalize;
  display: block;
}

.main-menu nav > ul > li:hover > .sub-menu li > a:hover {
  color: #fff;
  background: #096bd8;
}

.mob-menu{
  display: none !important;
}



.header-menu-3 .main-menu nav > ul > li:hover > .sub-menu li > a:hover {
  color: #fff;
  background: #d10459;
}

.header-menu-3 .main-menu nav > ul > li .sub-menu {
  border-top: 3px solid #d10459;
}

.header-menu-4 .main-menu nav > ul > li .sub-menu {
  border-top: 3px solid #d10459;
}

/* button style */
.btn {
  -moz-user-select: none;
  background: #096bd8;
  border: medium none;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 0;
  padding: 20px 40px;
  text-align: center;
  text-transform: uppercase;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 5px;
  position: relative;
  font-family: "cerebri_sansbold";
}

.btn-text {
  color: #ffffff;
  position: relative;
  transition: 0.3s;
}

.btn:hover .btn-text {
  color: #ffffff;
}

.btn-text i {
  padding-left: 5px;
}

.btn::before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  background: #096bd8;
  -webkit-transition: all 0.3s ease;
  transition: all 0.4s ease;
  border-radius: 5px;
}

.btn:hover::before {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
  background: #1876df;
}

.btn-white {
  background: #ffffff;
}

.btn-white .btn-text {
  color: #333333;
}

.btn:hover .btn-text {
  color: #ffffff;
}

.breadcrumb > .active {
  color: #888;
}

.pink-btn {
  background: #d10459;
}

.pink-btn .btn-text {
  color: #fff;
}

.pink-btn .btn-border {
  background: #fff;
}

.btn-white:hover::before {
  background: #fff;
}

.btn-white .btn-text:hover {
  color: #333333;
}

.btn-none::before {
  display: none;
}

.border-btn {
  -moz-user-select: none;
  border: 2px solid #ffffff;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 0;
  padding: 20px 40px;
  text-align: center;
  text-transform: uppercase;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 5px;
  position: relative;
  font-family: "cerebri_sansbold";
}

.btn-text {
  color: #ffffff;
  position: relative;
  transition: 0.3s;
}

.border-btn:hover {
  border-color: #096bd8;
}

.btn-text i {
  padding-left: 5px;
}

.border-btn::before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  background: #096bd8;
  -webkit-transition: all 0.3s ease;
  transition: all 0.4s ease;
  border-radius: 5px;
}

.border-btn:hover::before {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
  background: #1876df;
}

.about-area {
  background-size: cover;
  background-position: center center;
}

.about-text {
  margin-top: 32px;
}
.about-text > span {
  position: relative;
  color: #096bd8;
  font-size: 14px;
  font-family: "cerebri_sansbold";
  text-transform: uppercase;
  padding-left: 86px;
}
.about-text > span::after {
  background: #096bd8;
  height: 3px;
  width: 40px;
  content: "";
  position: absolute;
  left: 26px;
  top: 10px;
}
.about-text > span::before {
  background: #096bd8;
  height: 3px;
  width: 15px;
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
}
.about-text h1 {
  font-size: 45px;
  font-family: "cerebri_sanssemibold";
  line-height: 1.2;
  margin-top: 17px;
  margin-bottom: 22px;
}
.about-text h1 > span {
  color: #096bd8;
}
.about-text p {
  margin-bottom: 0;
}
.about-text a {
  color: #696969;
  font-size: 16px;
  transition: 0.3s;
  position: relative;
  font-family: "cerebri_sansbold";
  margin-top: 26px;
  display: inline-block;
}
.about-text a:hover {
  color: #096bd8;
}
.about-text a::before {
  content: "";
  width: 0;
  height: 1px;
  position: absolute;
  bottom: -2px;
  left: 100%;
  background: #096bd8;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 99;
}
.about-text a:hover:before {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.about-text a::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -2px;
  left: 0;
  background: #696969;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.about-us-img {
  position: relative;
  padding-right: 100px;
}
.about-us-img img {
  width: 100%;
}

.about-us-2-img {
  position: absolute;
  right: 28px;
  top: 268px;
}

.about-inner-img {
  position: relative;
}

.about-2-inner img {
  box-shadow: 0px 10px 32px 0px rgba(176, 176, 176, 0.69);
}

.about-video {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
}

.about-video a {
  height: 60px;
  width: 60px;
  background: #ffffff;
  display: inline-block;
  border-radius: 50%;
  line-height: 60px;
  text-align: center;
  font-size: 15px;
  transition: 0.3s;
  color: #096bd8;
  position: relative;
}

@-webkit-keyframes btnIconRipple {
  0% {
    border-width: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  80% {
    border-width: 1px;
    -webkit-transform: scale(1.35);
    transform: scale(1.35);
  }
  100% {
    opacity: 0;
  }
}
.about-video a::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: -2px;
  left: -2px;
  bottom: -2px;
  right: -2px;
  border-radius: inherit;
  border: 1px solid #fff;
  -webkit-animation: btnIconRipple 2s cubic-bezier(0.23, 1, 0.32, 1) both infinite;
  animation: btnIconRipple 2s cubic-bezier(0.23, 1, 0.32, 1) both infinite;
}

.about-us-wrapper {
  margin-left: 50px;
}

.about-us-text li {
  margin-bottom: 29px;
}
.about-us-text li:last-child {
  margin-bottom: 0;
}

.about-us-icon {
  float: left;
  margin-right: 20px;
}
.about-us-icon i {
  font-size: 24px;
  color: #096bd8;
  position: relative;
  top: 4px;
}

.about-us-content {
  overflow: hidden;
}
.about-us-content h4 {
  font-size: 22px;
  margin-bottom: 9px;
}
.about-us-content p {
  margin-bottom: 0;
}

.about-1-wrapper {
  padding-left: 25px;
  padding-right: 25px;
}

.about-1-wrapper .about-text {
  margin-top: 0;
}

.about-1-wrapper .about-text p {
  line-height: 32px;
}

.about-button {
  margin-top: 32px;
}

.about-2-wrapper {
  padding-left: 70px;
  padding-right: 63px;
  padding-top: 35px;
}

.about-2-wrapper .about-text h1 {
  margin-bottom: 29px;
}

.about-2-wrapper .about-button {
  margin-top: 43px;
}

.ab-01 {
  top: 0;
  right: 0;
}

.ab-02 {
  top: 18%;
  left: 39%;
}

.ab-03 {
  top: 24%;
  left: 10%;
}

.ab-04 {
  top: 64%;
  left: 0;
}

.ab-05 {
  bottom: 19%;
  left: 13%;
}

.about-1-single-text {
  margin-top: 25px;
}

.about-1-single-text p {
  color: #ffffff;
  line-height: 32px;
  margin-bottom: 25px;
  margin-right: 44px;
}

.about-1-single-text .about-button {
  margin-top: 38px;
}

.single-11-about .about-2-inner img {
  box-shadow: none;
}

.slider-area {
  position: relative;
}

.slider-overlay {
  position: relative;
}

.slider-overlay:before {
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
  left: 0;
  top: 0;
  background: #333333;
  opacity: 0.6;
}

.slider-height {
  min-height: 800px;
}

.slider-1-height {
  min-height: 850px;
}

.slider-2-height {
  min-height: 870px;
}

.slider-3-height {
  min-height: 950px;
}

.single-slider {
  background-size: cover;
  background-position: center center;
}

.slider-content h1 {
  font-family: "cerebri_sansbold";
  font-size: 85px;
  color: #ffffff;
  margin-bottom: 38px;
  letter-spacing: -3px;
  line-height: 1;
}
.slider-content p {
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
  margin-right: 232px;
  margin-bottom: 0;
}

.slider-button {
  margin-top: 50px;
}

.text-link {
  color: #ffffff;
  font-size: 16px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  font-family: "cerebri_sansbold";
  margin-left: 48px;
}

.text-link:hover {
  color: #096bd8;
}

.text-link::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -2px;
  left: 0;
  background: #c3c3c3;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.text-link::before {
  content: "";
  width: 0;
  height: 1px;
  position: absolute;
  bottom: -2px;
  left: 100%;
  background: #096bd8;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 99;
}

.text-link:hover::before {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.slider-video {
  margin-top: 115px;
}
.slider-video a {
  height: 80px;
  width: 80px;
  background: #ffffff;
  display: inline-block;
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  font-size: 16px;
  transition: 0.3s;
  color: #096bd8;
  position: relative;
}

@-webkit-keyframes btnIconRipple {
  0% {
    border-width: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  80% {
    border-width: 1px;
    -webkit-transform: scale(1.35);
    transform: scale(1.35);
  }
  100% {
    opacity: 0;
  }
}
.slider-video a::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: -2px;
  left: -2px;
  bottom: -2px;
  right: -2px;
  border-radius: inherit;
  border: 1px solid #fff;
  -webkit-animation: btnIconRipple 2s cubic-bezier(0.23, 1, 0.32, 1) both infinite;
  animation: btnIconRipple 2s cubic-bezier(0.23, 1, 0.32, 1) both infinite;
}

.slider-2-content h1 {
  margin-bottom: 62px;
  margin-top: 27px;
}

.slider-2-content span {
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
  font-family: "cerebri_sansbold";
  display: inline-block;
}

.agency {
  letter-spacing: 2px;
  background: #096bd8;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 5px;
}

.slider-btn {
  -moz-user-select: none;
  background: #ffffff;
  border: medium none;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 0;
  padding: 20px 40px;
  text-align: center;
  text-transform: uppercase;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 5px;
  position: relative;
  font-family: "cerebri_sansbold";
  color: #333333;
  margin-left: 9px;
}

.slider-btn i {
  padding-left: 5px;
}

.slider-btn:hover {
  background: #096bd8;
  color: #ffffff;
}

.slider-text > span {
  color: #ffffff;
  font-size: 35px;
  font-family: "cerebri_sansregular";
}

.slider-text h1 {
  font-size: 90px;
  letter-spacing: 0;
  margin-bottom: 27px;
}

.slider-text p {
  font-size: 18px;
  line-height: 32px;
  margin-right: 153px;
}

.slider-text .slider-button {
  margin-top: 37px;
}

.slider-active button.slick-arrow {
  position: absolute;
  top: 50%;
  left: 70px;
  transform: translateY(-50%);
  border: 0;
  font-size: 15px;
  padding: 0;
  color: #222;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  cursor: pointer;
  background: #fff;
}

.slider-active button.slick-next {
  left: auto;
  right: 70px;
}

.slider-active:hover button {
  opacity: 1;
  visibility: visible;
}

.slider-active button:hover {
  background: #096bd8;
  color: #fff;
}

.slider-2 .slider-active button.slick-arrow {
  background: #d10459;
  color: #fff;
}

.slider-2 .slider-active button:hover {
  background: #202AAE;
  color: #fff;
}

.slider-height-3 {
  min-height: 950px;
}

.slider-height-4 {
  min-height: 980px;
}

.slider-content-3 {
  padding-top: 200px;
}

.slider-content-3 > span {
  color: #096bd8;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 2px;
  font-family: "cerebri_sansbold";
}

.slider-content-3 h1 {
  color: #333333;
  margin-top: 16px;
}

.slider-content-3 .text-link {
  color: #333333;
}

.slider-content-3 .text-link:hover {
  color: #096bd8;
}

.slider-content-3 .text-link::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -2px;
  left: 0;
  background: #333333;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.slider-list {
  margin-top: 24px;
  margin-bottom: 37px;
}

.slider-list li {
  padding-bottom: 17px;
}
.slider-list li:last-child {
  padding-bottom: 0;
}

.slider-list li i {
  color: #fff;
  font-size: 20px;
  margin-right: 6px;
}

.slider-list li span {
  color: #fff;
  font-size: 20px;
  font-family: "cerebri_sansregular";
}

.slider-content-4 h1 {
  font-size: 75px;
}

.slider-content-4 p {
  margin-right: 0;
}

.slider-content-4 .border-btn {
  padding: 18px 40px;
  margin-left: 5px;
}

.footer-area {
  background-size: cover;
  background-position: center center;
}

.footer-logo {
  margin-bottom: 29px;
}

.footer-text p {
  margin-bottom: 0;
  line-height: 30px;
}

.footer-icon {
  margin-top: 22px;
}
.footer-icon a {
  color: #cbcbcb;
  font-size: 14px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin-right: 5px;
  transition: 0.3s;
  border: 1px solid #cbcbcb;
}
.footer-icon a:hover {
  background: #096bd8;
  color: #ffffff;
  border-color: #096bd8;
}

.footer-title {
  font-size: 20px;
  font-family: "cerebri_sansbold";
  position: relative;
  padding-bottom: 24px;
  margin-bottom: 30px;
}
.footer-title::before {
  position: absolute;
  height: 3px;
  width: 15px;
  background: #096bd8;
  left: 0;
  content: "";
  bottom: 0;
}
.footer-title::after {
  position: absolute;
  height: 3px;
  width: 40px;
  background: #096bd8;
  left: 25px;
  content: "";
  bottom: 0;
}

.fotter-menu li {
  padding-bottom: 10px;
}
.fotter-menu li:last-child {
  padding-bottom: 0;
}
.fotter-menu a {
  color: #696969;
  font-size: 15px;
  font-family: "cerebri_sansregular";
  transition: 0.3s;
}
.fotter-menu a:hover {
  color: #096bd8;
}

.footer-news {
  background: #fff;
  padding: 20px;
}

.footer-news ul li {
  overflow: hidden;
  padding-bottom: 15px;
}
.footer-news ul li:last-child {
  padding-bottom: 0;
}

.footer-news-img {
  float: left;
  margin-right: 15px;
}

.footer-news-text h5 {
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 0;
}
.footer-news-text h5 a {
  color: #a2a2a2;
  transition: 0.3s;
}
.footer-news-text h5 a:hover {
  color: #333333;
}

.footer-info p {
  margin-bottom: 19px;
}

.contact-link li {
  margin-bottom: 18px;
}
.contact-link li :last-child {
  margin-bottom: 0;
}

.contact-address-icon {
  float: left;
  margin-right: 11px;
}
.contact-address-icon i {
  font-size: 15px;
  color: #096bd8;
  position: relative;
  top: -4px;
}

.contact-address-text {
  overflow: hidden;
}
.contact-address-text h4 {
  font-size: 15px;
  color: #696969;
  margin-bottom: 0;
  font-family: "cerebri_sansregular";
}
.contact-address-text p {
  margin-bottom: 20px;
}

.footer-bg-bottom {
  background: #ffffff;
  padding: 25px 40px;
}

.copyright p {
  margin-bottom: 0;
}

.footer-bottem-text p {
  margin-bottom: 0;
}

.footer-bottem-text a {
  color: #096bd8;
}

.footer-news-text {
  overflow: hidden;
}

.footer-2-wrapper .footer-text p {
  color: #b8b8b8;
  padding-right: 68px;
  margin-bottom: 30px;
}

.footer-2-wrapper .footer-title {
  color: #ffffff;
}

.footer-2-wrapper .fotter-menu a {
  color: #b8b8b8;
  transition: 0.3s;
}

.footer-2-wrapper .fotter-menu a:hover {
  color: #096bd8;
}

.footer-2-wrapper .footer-info p {
  color: #b8b8b8;
}

.footer-2-wrapper .contact-address-text h4 {
  color: #b8b8b8;
}

.footer-2-bottom {
  border-top: 1px solid #1b2f3e;
}

.footer-2-bottom .copyright p {
  color: #b8b8b8;
}

.footer-2-bottom .copyright p a {
  color: #096bd8;
}

.footer-2-bottom .footer-bottem-text p {
  color: #b8b8b8;
}

.footer-2-icon a {
  height: 60px;
  width: 60px;
  background: #1b2f3e;
  line-height: 60px;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  transition: 0.3s;
  margin-right: 5px;
  color: #cbcbcb;
  font-size: 18px;
}
.footer-2-icon a:last-child {
  margin-right: 0;
}
.footer-2-icon a:hover {
  background: #096bd8;
  color: #ffffff;
}

.footer-2-menu li {
  float: left;
  width: 50%;
}

.footer-2-news {
  background: 0;
  padding: 0;
}

.footer-2-news .footer-news-text h5 a {
  color: #696969;
  transition: 0.3s;
}

.footer-2-news .footer-news-text h5 a:hover {
  color: #333333;
}

.footer-3-icon a {
  background: #ffffff;
  color: #5aa7fd;
}

.footer-3-bottom {
  border-top: 2px solid #ecf1f4;
}

.footer-3-bottom .copyright p a {
  color: #096bd8;
}

.footer-4-icon {
  margin-top: 36px;
}

.footer-4-icon a {
  height: 45px;
  width: 45px;
  background: #1b2f3e;
  line-height: 45px;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  transition: 0.3s;
  margin-right: 5px;
  color: #cbcbcb;
  font-size: 14px;
}

.subscribes-form form input {
  border: 0;
  border-radius: 5PX;
  height: 60px;
  padding: 0 25px;
  width: 100%;
  background: #ffffff;
  color: #bababa;
  font-size: 15px;
}

.subscribes-form form input::placeholder {
  color: #bababa;
  font-size: 15px;
  font-family: "cerebri_sansregular";
}

.subscribes-form form input::-moz-placeholder {
  color: #bababa;
  font-size: 15px;
  font-family: "cerebri_sansregular";
}

.subscribes-form form button.btn {
  margin-top: 10px;
  padding: 20px 26px;
}

.services-area {
  background-size: cover;
  background-position: center center;
}

.services-wrapper {
  background: #ffffff;
  padding: 60px 40px 60px 41px;
  border-radius: 10px;
}

.about-img {
  margin-left: 25px;
}

.about-img img {
  width: 100%;
}

.about-content {
  margin-left: 51px;
  margin-top: 30px;
}
.about-content p {
  margin-bottom: 0;
}

.about-1-img {
  margin-top: 34px;
}

.services-text h3 {
  font-family: "cerebri_sanssemibold";
  font-size: 24px;
  margin-top: 23px;
  margin-bottom: 21px;
}
.services-text p {
  margin-bottom: 0;
  line-height: 30px;
}

.services-text a {
  position: relative;
  display: inline-block;
  margin-top: 24px;
  font-family: "cerebri_sanssemibold";
  text-transform: uppercase;
  font-size: 14px;
  padding: 13px 29px;
  transition: 0.3s;
  border-radius: 5px;
  vertical-align: middle;
  border: 2px solid #ececec;
}

.services-button {
  color: #096bd8;
  z-index: 9;
  position: relative;
  transition: 0.3s;
}

.services-button i {
  margin-left: 5px;
}

.services-text a:hover .services-button {
  color: #ffffff;
}

.services-text a:hover {
  border-color: #1065c4;
}

.services-text a::before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  background: #096bd8;
  -webkit-transition: all 0.3s ease;
  transition: all 0.4s ease;
}

.services-text a:hover::before {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.section-title > span {
  color: #096bd8;
  font-family: "cerebri_sansbold";
  font-size: 14px;
  text-transform: uppercase;
}
.section-title h1 {
  font-size: 45px;
  margin-bottom: 0;
  font-family: "cerebri_sansbold";
  margin-top: 21px;
}
.section-title h1 > span {
  color: #096bd8;
}
.section-title p {
  margin-bottom: 0;
  margin-top: 34px;
  font-size: 15px;
  line-height: 30px;
}

.border-left-1 {
  height: 3px;
  width: 40px;
  background: #096bd8;
  left: -15px;
  display: inline-block;
  position: relative;
  top: -3px;
}

.border-right-1 {
  height: 3px;
  width: 40px;
  background: #096bd8;
  right: -15px;
  display: inline-block;
  top: -3px;
  position: relative;
}

.sub-t-left {
  padding-left: 20px;
}

.b-sm-left-1 {
  height: 3px;
  width: 15px;
  background: #096bd8;
  left: 0;
  display: inline-block;
  position: relative;
  top: -3px;
}

.b-sm-left-2 {
  height: 3px;
  width: 40px;
  background: #096bd8;
  display: inline-block;
  top: -3px;
  position: relative;
  left: 5px;
}

.section-title-white > span {
  color: #ffffff;
}
.section-title-white h1 {
  color: #ffffff;
}
.section-title-white p {
  color: #bbbbbb;
}

.section-title-white .b-sm-left-1 {
  background: #ffffff;
}

.section-title-white .b-sm-left-2 {
  background: #ffffff;
}

.section-title-white .border-left-1 {
  background: #ffffff;
}

.section-title-white .border-right-1 {
  background: #ffffff;
}

.our-services-wrapper {
  background: #fff;
  box-shadow: 0px 10px 60px 0px rgba(231, 231, 231, 0.65);
  padding: 30px;
}

.our-services-img img {
  width: 100%;
}

.our-services-icon {
  float: left;
  margin-right: 30px;
}
.our-services-icon i {
  font-size: 40px;
  color: #096bd8;
}

.our-services-content {
  margin-top: 40px;
}

.our-services-text {
  overflow: hidden;
}
.our-services-text h3 {
  font-size: 24px;
  margin-bottom: 12px;
}
.our-services-text p {
  margin-bottom: 0;
}
.our-services-text a {
  font-size: 14px;
  text-transform: uppercase;
  color: #096bd8;
  font-family: "cerebri_sansbold";
  margin-top: 25px;
  display: inline-block;
}
.our-services-text a i {
  padding-left: 5px;
}

.services-2-wrapper {
  padding-right: 77px;
}

.single-services {
  position: relative;
  padding: 70px 38px 60px;
}

.single-services::before {
  background-image: url("../img/service/01.jpg");
  background-repeat: no-repeat;
  content: "";
  height: 100%;
  position: absolute;
  transition: all 0.3s ease 0s;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  background-size: cover;
}

.single-services:hover::before {
  opacity: 1;
}

.single-services.active::before {
  opacity: 1;
}

.services-icon {
  position: relative;
  margin-bottom: 35px;
}
.services-icon::before {
  position: absolute;
  content: "";
  height: 60px;
  width: 60px;
  background: #efefef;
  border-radius: 50%;
  z-index: 1;
  right: 110px;
  top: 13px;
  transition: 0.3s;
}
.services-icon::after {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  background: #efefef;
  border-radius: 50%;
  z-index: 1;
  left: 111px;
  top: -8px;
  transition: 0.3s;
}
.services-icon i {
  font-size: 60px;
  color: #096bd8;
  position: relative;
  z-index: 9;
  transition: 0.3s;
}

.single-services:hover .services-icon i {
  color: #ffffff;
}

.single-services:hover .services-icon::before {
  display: none;
}

.single-services:hover .services-icon::after {
  display: none;
}

.single-services.active .services-icon i {
  color: #ffffff;
}

.single-services.active .services-icon::before {
  display: none;
}

.single-services.active .services-icon::after {
  display: none;
}

.single-services .services-text {
  z-index: 999;
  position: relative;
}

.single-services .services-text h3 {
  margin-bottom: 11px;
  transition: 0.3s;
}

.single-services:hover .services-text h3 {
  color: #fff;
}

.single-services:hover .services-text p {
  color: #fff;
}

.single-services.active .services-text h3 {
  color: #fff;
}

.single-services.active .services-text p {
  color: #fff;
}

.services-button i {
  margin-left: 5px;
}

.single-services:hover .services-button {
  color: #ffffff;
}

.single-services:hover .services-text a {
  border-color: #1065c4;
}

.single-services.active .services-button {
  color: #ffffff;
}

.single-services.active .services-text a {
  border-color: #1065c4;
}

.single-services:hover .services-text a::before {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.single-services.active .services-text a::before {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.services-3-wrapper .services-text h3 {
  margin-bottom: 11px;
}

.services-shape-left {
  position: absolute;
  left: -151px;
  bottom: 94px;
}

.services-shape-right {
  position: absolute;
  right: -134px;
  bottom: 94px;
}

.management-wrapper {
  margin-top: 36px;
}

.management-text h1 {
  font-size: 45px;
  margin-bottom: 36px;
}
.management-text p {
  margin-bottom: 22px;
  padding-right: 5px;
}
.management-text p:last-child {
  margin-bottom: 0;
}

.it-management-area {
  background-size: cover;
  background-position: center center;
}

.it-management-img img {
  width: 100%;
}

.it-management-wrapper {
  margin-left: 20px;
}
.it-management-wrapper .it-management-text h1 {
  color: #ffffff;
  margin-bottom: 32px;
  font-size: 45px;
}
.it-management-wrapper .it-management-text span {
  color: #ffffff;
  line-height: 30px;
  font-size: 18px;
  font-family: "cerebri_sansmedium";
}
.it-management-wrapper .it-management-text p {
  margin-bottom: 0;
  color: #ffffff;
  margin-top: 21px;
}

.our-goals-img img {
  width: 100%;
}

.our-golas-text {
  margin-top: 29px;
}

.our-golas-text h3 {
  font-size: 24px;
}

.our-golas-text p {
  line-height: 30px;
  margin-bottom: 0;
  margin-right: 13px;
}

.goals-area {
  background: #091b29;
}

.goals-img {
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 50%;
  background-position: center center;
}

.golas-wrapper {
  padding: 120px 67px 130px 55px;
}

.golas-text p {
  color: #bbbbbb;
  line-height: 30px;
  margin-bottom: 0;
}

.goals-list {
  margin-top: 24px;
  margin-bottom: 37px;
}

.goals-list li {
  padding-bottom: 12px;
}
.goals-list li:last-child {
  padding-bottom: 0;
}

.goals-list li i {
  color: #096bd8;
  font-size: 15px;
  margin-right: 6px;
}

.goals-list li span {
  color: #bbbbbb;
  font-size: 15px;
}

.goals-list li span {
  color: #bbbbbb;
  font-size: 15px;
  font-family: "cerebri_sansregular";
}

.features-area {
  margin-top: -106px;
  position: relative;
}

.features-wrapper {
  border-right: 1px solid #2084f2;
}

.features-bg .col-xl-4:last-child .features-wrapper {
  border-right: 0;
}

.features-icon {
  margin-bottom: 33px;
}
.features-icon i {
  font-size: 50px;
  color: #ffffff;
}

.features-text h3 {
  font-size: 24px;
  color: #ffffff;
  font-family: "cerebri_sanssemibold";
  margin-bottom: 10px;
}
.features-text p {
  color: #ffffff;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 30px;
  padding: 0 67px;
}

.services-tab-area {
  background-size: cover;
  background-position: center center;
}

.services-tab li {
  margin: 0;
  position: relative;
  padding: 0 5px;
}

.services-tab li:last-child {
  padding-right: 0;
}

.services-tab li:first-child {
  padding-left: 0;
}

.services-tab li:last-child:before {
  display: none;
}

.services-tab li a {
  transition: 0.3s;
  background: #19354b;
  padding: 28px 42px;
  margin: 0;
}

.services-tab li a:hover {
  background: #096bd8;
}

.services-tab li a.active {
  background: #096bd8;
}

.services-tap-icon {
  float: left;
  margin-right: 12px;
}
.services-tap-icon i {
  font-size: 26px;
  color: #a8cbe6;
  transition: 0.3s;
}

.services-tab li a:hover .services-tap-icon i {
  color: #ffffff;
}

.services-tab li a.active .services-tap-icon i {
  color: #ffffff;
}

.services-tap-text {
  overflow: hidden;
}
.services-tap-text h4 {
  font-size: 22px;
  color: #a8cbe6;
  font-family: "cerebri_sanssemibold";
  margin-bottom: 0;
  transition: 0.3s;
}

.services-tab li a.active .services-tap-text h4 {
  color: #ffffff;
}

.services-tab li a:hover .services-tap-text h4 {
  color: #ffffff;
}

.team-img img {
  width: 100%;
  height: 280px;
}

.team-text {
  border: 1px solid #ebebeb;
  padding: 22px 30px 30px 30px;
}
.team-text h4 {
  font-size: 22px;
  font-family: "cerebri_sansbold";
  margin-bottom: 3px;
}
.team-text .deba {
  font-size: 20px;
  font-family: "cerebri_sansbold";
  margin-bottom: 3px;
}
.team-text span {
  font-size: 14px;
  text-transform: uppercase;
  color: #ababab;
  font-family: "cerebri_sansregular";
}

.team-icon {
  margin-top: 9px;
}

.team-icon a {
  color: #cbcbcb;
  font-size: 14px;
  transition: 0.3s;
  margin-right: 15px;
}

.team-icon a:last-child {
  margin-right: 0;
}

.team-icon a:hover {
  color: #096bd8;
}

.team-section p {
  margin-bottom: 26px;
}

.shape-item {
  position: absolute;
}

.team-01 {
  top: 24%;
  right: 11%;
}

.team-02 {
  top: 13%;
  right: 11%;
}

.team-2-wrapper {
  padding: 30px 20px;
}

.team-content {
  margin-top: 24px;
}
.team-content h4 {
  color: #ffffff;
  font-family: "cerebri_sansbold";
  font-size: 22px;
  margin-bottom: 5px;
}
.team-content span {
  color: #ffffff;
  font-size: 14px;
  font-family: "cerebri_sansregular";
  text-transform: uppercase;
}

.team-content .team-icon {
  margin-top: 25px;
}

.team-content .team-icon a {
  font-size: 14px;
  transition: 0.3s;
  margin: 0 2px;
  height: 40px;
  width: 40px;
  background: #1478e8;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  color: #9fccff;
}
.team-content .team-icon a:hover {
  background: #ffffff;
  color: #096bd8;
}

.single-team-wrapper {
  margin-right: 95px;
  margin-top: 24px;
}

.single-team-text h1 {
  font-size: 45px;
}

.single-team-text > span {
  font-size: 16px;
  text-transform: uppercase;
  color: #096bd8;
  font-family: "cerebri_sansbold";
}

.single-team-text p {
  margin-bottom: 0;
  margin-top: 26px;
}

.team-single {
  margin-top: 37px;
}

.single-team-icon {
  float: left;
  margin-right: 20px;
}

.team-info > span {
  color: #828282;
  font-size: 16px;
}

.team-info h4 {
  margin-bottom: 0;
  font-size: 20px;
  margin-top: 3px;
}

.single-team-info {
  overflow: hidden;
  margin-bottom: 30px;
}

.single-team-info:last-child {
  margin-bottom: 0;
}

.team-2-icon span {
  color: #333333;
  font-size: 20px;
  font-family: "cerebri_sansbold";
  margin-right: 7px;
}

.team-2-icon {
  margin-top: 40px;
}
.team-2-icon a {
  height: 45px;
  width: 45px;
  background: #f0f0f0;
  line-height: 45px;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  transition: 0.3s;
  color: #a8a8a8;
  font-size: 16px;
}
.team-2-icon a:hover {
  background: #096bd8;
  color: #ffffff;
}

.cta-area {
  background-size: cover;
  background-position: center center;
}

.cta-text span {
  color: #ffffff;
  font-family: "cerebri_sansbold";
  text-transform: uppercase;
  font-size: 14px;
}
.cta-text h1 {
  font-size: 45px;
  color: #ffffff;
  font-family: "cerebri_sansbold";
  margin-bottom: 0;
  margin-top: 11px;
}

.cta-button {
  margin-top: 42px;
}

.cta-link {
  font-size: 15px;
  color: #fff;
  margin-left: 25px;
  font-weight: 400;
}
.cta-link i {
  margin-right: 5px;
}
.cta-link:hover {
  color: #ffffff;
}

.cta-button .btn.btn-white:hover::before {
  background: #fff;
}

.cta-button .btn:hover .btn-text {
  color: #333333;
}

.cta-content {
  margin-right: 34%;
}
.cta-content h1 {
  font-size: 45px;
  color: #fff;
  margin-bottom: 23px;
}
.cta-content p {
  color: #ffffff;
  margin-bottom: 32px;
}

.cta-2-button a.border-btn {
  margin-left: 17px;
  padding: 19px 40px;
}

.ctas-info {
  background: #ffffff;
  background: #ffffff;
  margin-left: 30px;
  padding: 52px 68px 56px 68px;
  margin-top: 72px;
}
.ctas-info h3 {
  color: #096bd8;
  font-size: 26px;
  position: relative;
}
.ctas-info h3::before {
  color: #096bd8;
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-size: 85px;
  position: absolute;
  line-height: 1;
  top: -26px;
  z-index: 9999;
  opacity: 0.1;
  left: 52px;
}

.btn-margin {
  margin-left: 5px;
}

.project-img {
  position: relative;
}

.project-img::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: #42495b;
  opacity: 0;
  transition: 0.3s;
}

.owl-item.center .project-img::before {
  opacity: 0.3;
}

.project-img img {
  width: 100%;
}

.project-text {
  position: absolute;
  bottom: 30px;
  transition: 0.3s;
  background: #ffffff;
  padding: 20px 60px 26px 40px;
  margin: 30px 30px 0 30px;
  transition: 0.3s;
  opacity: 0;
}
.project-text span {
  color: #096bd8;
  font-family: "cerebri_sansbold";
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 13px;
}
.project-text h3 {
  font-family: "cerebri_sansbold";
  font-size: 24px;
  margin-bottom: 0;
  transition: 0.3s;
  margin-top: 3px;
}
.project-text h3 a:hover {
  color: #096bd8;
}

.owl-item.center .project-text {
  opacity: 1;
}

.project-active .owl-nav div {
  background: #f6f2ed;
  font-size: 18px;
  left: -35px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  color: #9f9f9f;
  font-weight: 400;
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 50%;
  transform: translateY(-50%);
}

.project-active .owl-nav div.owl-next {
  left: auto;
  right: -35px;
}

.project-active:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

.project-active .owl-nav div:hover {
  background: #096bd8;
  color: #fff;
  box-shadow: 0px 10px 32px 0px rgba(9, 107, 216, 0.61);
}

.project-active .owl-dots {
  bottom: -69px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 99;
}

.project-active .owl-dot {
  display: inline-block;
  height: 6px;
  margin: 0 5px;
  width: 20px;
  background: #d5e0ff;
  transition: 0.3s;
  border-radius: 3px;
}

.project-active .owl-dot.active {
  position: relative;
  background: #096bd8;
  width: 40px;
}

.portfolio-menu button {
  border: medium none;
  color: #696969;
  font-size: 16px;
  line-height: 1;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  text-transform: capitalize;
  padding: 0;
  font-family: "cerebri_sanssemibold";
  position: relative;
  background: none;
  margin-right: 35px;
}

.portfolio-menu button:last-child {
  margin-right: 0;
}

.portfolio-menu button:focus {
  outline: 0 none;
}

.portfolio-menu button:hover {
  color: #096bd8;
}

.portfolio-menu button.active {
  color: #096bd8;
}

.custom {
  margin: 0 -15px;
}

.portfolio-img {
  position: relative;
}

.portfolio-img > a {
  position: relative;
  display: inline-block;
}

.portfolio-img > a::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #091b29;
  opacity: 0;
  left: 0;
  top: 0;
  transition: 0.3s;
}

.portfolio-wrapper:hover .portfolio-img > a::before {
  opacity: 0.5;
}

.portfolio-img > a img {
  width: 100%;
}

.portfolio-text {
  position: absolute;
  left: 30px;
  right: 40px;
  transition: 0.3s;
  opacity: 0;
  background: #ffffff;
  bottom: 30px;
  padding: 19px 31px 25px 30px;
  border-radius: 10px;
}
.portfolio-text h3 {
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.portfolio-text h3 > a {
  color: #333333;
  transition: 0.3s;
}
.portfolio-text > span {
  font-size: 13px;
  text-transform: uppercase;
  color: #096bd8;
  font-family: "cerebri_sansbold";
  letter-spacing: 2px;
}
.portfolio-text > a {
  padding: 13px 26px;
}

.portfolio-wrapper:hover .portfolio-text {
  opacity: 1;
}

.case-img > a img {
  width: 100%;
}

.case-text {
  padding: 30px 30px 40px 40px;
  background: #096bd8;
}
.case-text span {
  font-size: 13px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "cerebri_sansbold";
}
.case-text h3 {
  font-size: 26px;
  margin-bottom: 0;
  margin-top: 3px;
}
.case-text h3 > a {
  color: #ffffff;
}

.case-studies-img {
  position: relative;
}
.case-studies-img::before {
  position: absolute;
  background-image: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-image: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 100%);
  content: "";
  height: 100%;
  width: 100%;
  transition: 0.3s;
}
.case-studies-img img {
  width: 100%;
}

.case-studies-img .case-studies-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 15px 15px 40px 15px;
}
.case-studies-img .case-studies-text span {
  font-size: 13px;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 2px;
  font-family: "cerebri_sansbold";
}
.case-studies-img .case-studies-text h3 {
  margin-bottom: 0;
  font-size: 24px;
  margin-top: 4px;
}
.case-studies-img .case-studies-text h3 a {
  color: #ffffff;
}

.case-studies-content {
  margin-top: 27px;
  padding: 0 20px;
}
.case-studies-content p {
  margin-bottom: 0;
  line-height: 30px;
}

.our-case-area {
  background-size: cover;
  background-position: center center;
}

.our-case-img {
  border-radius: 10px 10px 0 0;
}
.our-case-img img {
  width: 100%;
}

.our-case-text {
  background: #ffffff;
  padding: 30px 32px 40px 30px;
  border-radius: 0 0 10px 10px;
}
.our-case-text h3 {
  font-size: 24px;
  margin-bottom: 15px;
}
.our-case-text h3 > a {
  color: #333333;
}
.our-case-text h3 > a span {
  font-size: 15px;
  color: #096bd8;
  font-family: "cerebri_sansregular";
}
.our-case-text p {
  margin-bottom: 0;
  line-height: 30px;
}

.our-case-text > a {
  position: relative;
  display: inline-block;
  margin-top: 26px;
  font-family: "cerebri_sanssemibold";
  text-transform: uppercase;
  font-size: 14px;
  padding: 13px 29px;
  transition: 0.3s;
  border-radius: 5px;
  vertical-align: middle;
  border: 2px solid #096bd8;
}

.cases-button {
  color: #096bd8;
  z-index: 999;
  position: relative;
  transition: 0.3s;
}

.cases-button i {
  margin-left: 5px;
}

.our-case-text > a:hover .cases-button {
  color: #ffffff;
}

.our-case-text > a:hover {
  border-color: #1065c4;
}

.our-case-text > a::before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  background: #096bd8;
  -webkit-transition: all 0.3s ease;
  transition: all 0.4s ease;
}

.our-case-text > a:hover::before {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.case-active .owl-dots {
  bottom: -96px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 99;
}

.case-active .owl-dot {
  display: inline-block;
  height: 12px;
  margin: 0 5px;
  width: 12px;
  background: none;
  transition: 0.3s;
  border-radius: 50%;
  border: 2px solid #ffffff;
}

.case-active .owl-dot.active {
  position: relative;
  background: #ffffff;
}

.case-details-img {
  margin-bottom: 50px;
}

.case-details-img img {
  width: 100%;
}

.case-details-text h1 {
  font-size: 45px;
  margin-bottom: 18px;
}

.case-details-text span {
  font-size: 24px;
  line-height: 40px;
  color: #333333;
  font-family: "cerebri_sansregular";
}

.case-post-tag {
  margin-top: 96px;
}

.case-post-tag span {
  font-size: 15px;
  color: #333333;
  font-family: "cerebri_sanssemibold";
}

.case-post-tag > a {
  color: #696969;
  display: inline-block;
  font-size: 15px;
  font-family: "cerebri_sansregular";
}

.case-post-tag > a:hover {
  color: #096bd8;
}

.case-details-content {
  margin-left: 70px;
  margin-top: 54px;
}

.case-details-content p {
  line-height: 32px;
  margin-bottom: 42px;
}

.case-share-icon > span {
  font-size: 15px;
  color: #333333;
  font-family: "cerebri_sanssemibold";
}

.case-share-icon > a {
  color: #9b9b9b;
  font-size: 14px;
  margin: 0 3px;
  transition: 0.3s;
  height: 40px;
  width: 40px;
  background: #e7e7e7;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
}

.case-share-icon > a:hover {
  color: #fff;
  background: #096bd8;
}

.gallery-layout-bg {
  padding: 75px 84px 84px 85px;
  overflow: hidden;
  z-index: 9;
  position: relative;
  border: 10px solid #1478e8;
}

.gallery-layout-info {
  float: left;
  margin-right: 68px;
}

.gallery-layout-info:last-child {
  margin-right: 0;
}

.gallery-layout-info span {
  font-size: 20px;
  color: #696969;
  font-family: "cerebri_sanssemibold";
}

.gallery-layout-info h5 {
  font-size: 30px;
  margin-top: 6px;
  margin-bottom: 0;
}

.case-studies-info p {
  margin-bottom: 0;
  line-height: 30px;
  margin-right: 28px;
}

.testimonial-wrapper {
  position: relative;
}

.testimonial-wrapper::before {
  color: #333333;
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-size: 240px;
  position: absolute;
  line-height: 1;
  top: 51px;
  right: 163px;
  z-index: -1;
  opacity: 0.07;
}

.testimonial-text p {
  font-size: 18px;
  line-height: 33px;
  margin-bottom: 0;
  margin-right: 124px;
}
.testimonial-text h4 {
  font-size: 22px;
  font-family: "cerebri_sansbold";
  margin-bottom: 1px;
  margin-top: 32px;
}
.testimonial-text span {
  font-size: 13px;
  color: #096bd8;
  font-family: "cerebri_sansbold";
  letter-spacing: 2px;
  text-transform: uppercase;
}

.testimonial-img {
  margin-left: -28px;
  margin-top: 4px;
}

.testimonial-active .owl-nav div {
  color: #9f9f9f;
  font-size: 14px;
  position: absolute;
  left: 0;
  transition: all 0.3s ease 0s;
  bottom: -98px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  background: #f6f2ed;
  border-radius: 50%;
}

.testimonial-active .owl-nav div.owl-prev {
  left: 60px;
}

.testimonial-active .owl-nav div:hover {
  background: #096bd8;
  color: #fff;
  box-shadow: 0px 10px 32px 0px rgba(9, 107, 216, 0.61);
}

.client-say-text {
  padding: 36px 39px 50px 35px;
  position: relative;
  background: #fff;
  box-shadow: 0px 10px 32px 0px rgba(225, 225, 225, 0.19);
}
.client-say-text::after {
  position: absolute;
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  display: inline-block;
  color: #696969;
  font-size: 139px;
  text-align: center;
  right: 50px;
  bottom: 35px;
  line-height: 1;
  z-index: -1;
  opacity: 0.1;
}
.client-say-text p {
  line-height: 34px;
  margin-bottom: 26px;
  font-size: 18px;
}

.client-rating {
  margin-bottom: 35px;
}
.client-rating i {
  color: #ff9900;
  font-size: 14px;
  margin-right: 1px;
}

.client-say-content {
  padding-top: 9px;
  overflow: hidden;
}
.client-say-content h4 {
  font-size: 20px;
  margin-bottom: 0;
  color: #333333;
  font-family: "cerebri_sansbold";
}
.client-say-content span {
  font-size: 14px;
  color: #ff9900;
  text-transform: uppercase;
  font-family: "cerebri_sansbold";
}

.client-say-img {
  float: left;
  margin-right: 15px;
}
.client-say-img img {
  border-radius: 50%;
}

.client-active .owl-nav div {
  background: #ffffff;
  height: 50px;
  width: 50px;
  right: 15px;
  font-size: 16px;
  content: "";
  z-index: -1;
  top: -130px;
  transition: 0.3s;
  position: absolute;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
  color: #096bd8;
}

.client-active .owl-nav div.owl-prev {
  right: 75px;
}

.client-active .owl-nav div:hover {
  background: #096bd8;
  color: #ffffff;
  box-shadow: 0px 10px 32px 0px rgba(203, 203, 203, 0.51);
}

.single-testimonial {
  border: 1px solid #e2e2e2;
  margin-left: 34px;
  margin-right: 34px;
  padding: 0 50px 55px 70px;
  position: relative;
  margin-top: 32px;
  margin-bottom: 39px;
}

.single-testimonial::before {
  color: #096bd8;
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-size: 30px;
  position: absolute;
  right: 90px;
  font-weight: 700;
  bottom: -40px;
  border: 1px solid #e7e7e7;
  height: 80px;
  width: 80px;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  background: #fff;
}

.single-testimonial .clientsay-name {
  margin-top: -32px;
  background: #fff;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
}

.testimonial-2-text {
  padding-left: 20px;
  padding-top: 35px;
}

.testimonial-2-text p {
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 37px;
}

.testimonial-skill span {
  color: #696969;
  font-size: 18px;
  margin-right: 11px;
  font-family: "cerebri_sansbold";
}

.testimonial-skill span:last-child {
  margin-right: 0;
}

.testimonial-skill span i {
  font-size: 14px;
  color: #ff9900;
}

.testimonial-2-active .owl-nav div {
  background: none;
  font-size: 18px;
  left: -158px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  color: #096bd8;
  font-weight: 400;
  height: 60px;
  width: 60px;
  line-height: 60px;
  transform: translateY(-50%);
  border: 2px solid #e2e2e2;
  border-radius: 5px;
}

.testimonial-2-active .owl-nav div.owl-next {
  left: auto;
  right: -158px;
}

.testimonial-2-active:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

.testimonial-2-active .owl-nav div:hover {
  background: #096bd8;
  color: #fff;
  border-color: #096bd8;
}

.testimonial-2-active .owl-dots {
  bottom: -57px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 99;
}

.testimonial-2-active .owl-dot {
  display: inline-block;
  height: 5px;
  margin: 0 4px;
  width: 10px;
  background: #096bd8;
  transition: 0.3s;
  border-radius: 3px;
}

.testimonial-2-active .owl-dot.active {
  position: relative;
  width: 30px;
}

.test-01 {
  top: -128px;
  right: 0;
}

.test-02 {
  top: 26%;
  left: 6%;
}

.counter-area {
  background-size: cover;
  background-position: center center;
}

.counter-icon i {
  color: #ffffff;
  font-size: 40px;
}

.counter-text h1 {
  font-size: 55px;
  margin-top: 22px;
  color: #fff;
  font-family: "cerebri_sansbold";
}

.counter-text p {
  font-size: 18px;
  color: #fff;
  line-height: 1;
  margin-bottom: 0;
  font-family: "cerebri_sansmedium";
}

.plus-icon {
  position: relative;
  top: -30px;
  font-size: 40px;
  right: 2px;
}

.single-counter .counter-icon i {
  color: #096bd8;
}

.single-counter .counter-icon i {
  color: #096bd8;
}

.single-counter .counter-text h1 {
  color: #333333;
}

.single-counter .plus-icon {
  color: #096bd8;
}

.single-counter .counter-text p {
  color: #afafaf;
}

.counter-bg {
  margin-left: 50px;
  margin-top: 45px;
}

.single-2-counter .counter-text h1 {
  color: #096bd8;
  margin-top: 0;
  margin-bottom: 19px;
}

.single-2-counter .counter-text h4 {
  font-size: 18px;
  font-family: "cerebri_sanssemibold";
  margin-bottom: 11px;
}

.single-2-counter .counter-text p {
  color: #696969;
  line-height: 30px;
  font-size: 15px;
  font-family: "cerebri_sansregular";
}

.video-area {
  background-size: cover;
  background-position: center center;
  padding-bottom: 630px;
}

.video-wrapper {
  margin-left: 30px;
  margin-top: 45px;
}

.video-img {
  position: relative;
}

.video-img img {
  width: 100%;
}

.video-img:before {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #091b29;
  left: 0;
  top: 0;
  content: "";
  opacity: 0.7;
}

.video-icon {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
}
.video-icon a {
  height: 80px;
  width: 80px;
  background: #ffffff;
  display: inline-block;
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  font-size: 16px;
  transition: 0.3s;
  color: #096bd8;
  position: relative;
}

@-webkit-keyframes btnIconRipple {
  0% {
    border-width: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  80% {
    border-width: 1px;
    -webkit-transform: scale(1.35);
    transform: scale(1.35);
  }
  100% {
    opacity: 0;
  }
}
.video-icon a::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: -2px;
  left: -2px;
  bottom: -2px;
  right: -2px;
  border-radius: inherit;
  border: 1px solid #fff;
  -webkit-animation: btnIconRipple 2s cubic-bezier(0.23, 1, 0.32, 1) both infinite;
  animation: btnIconRipple 2s cubic-bezier(0.23, 1, 0.32, 1) both infinite;
}

.contact-area {
  margin-top: -538px;
}

.contact-bg {
  box-shadow: 0px 16px 32px 0px rgba(228, 228, 228, 0.56);
  background: #ffffff;
  padding: 60px 60px 30px 60px;
}

.contact-map {
  min-height: 390px;
}

#appointment-form input {
  background: none;
  height: 60px;
  width: 100%;
  padding: 0 30px;
  padding-right: 30px;
  border: 0;
  color: #9d9d9d;
  font-weight: 400;
  border: 1px solid #ebebeb;
  font-size: 15px;
  text-transform: capitalize;
  border-radius: 5px;
}
/* #appointment-form .form-select {
  background: none !important;
  height: 60px !important;
  width: 100% !important;
  padding: 0 30px !important;
  padding-right: 30px !important;
  border: 0 !important;
  color: #9d9d9d !important;
  font-weight: 400 !important;
  border: 1px solid #ebebeb !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
  border-radius: 5px !important;
} */

#appointment-form textarea {
  color: #9d9d9d;
  font-weight: 400;
  height: 120px;
  width: 100%;
  padding: 30px 30px;
  border: 1px solid #ebebeb;
  text-transform: capitalize;
  background: none;
  border-radius: 5px;
}

#appointment-form input::-moz-placeholder, .appointment-form input::placeholder {
  color: #9d9d9d;
  font-size: 15px;
}

#appointment-form textarea::-moz-placeholder, .appointment-form textarea::placeholder {
  color: #9d9d9d;
  font-size: 15px;
}

.form-box {
  position: relative;
}

.form-box::after {
  position: absolute;
  content: "";
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
  font-size: 16px;
  text-align: center;
  right: 20px;
  color: #096bd8;
  top: 19px;
}

.email-icon:after {
  content: "";
}

.date-icon::after {
  content: "";
}

.person-icon:after {
  content: "";
}

.time-icon:after {
  content: "";
}

.message-icon:after {
  content: "";
}

#contact-form textarea {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: currentcolor currentcolor #328ef4;
  border-image: none;
  border-style: none none solid;
  border-width: 0 0 1px;
  color: #ffffff;
  font-size: 14px;
  height: 98px;
  text-transform: capitalize;
  width: 100%;
  background: none;
  transition: 0.3s;
}

.contact-wrapper {
  margin-right: 70px;
}

#contact-form input {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #328ef4;
  border-image: none;
  border-style: none none solid;
  border-width: 0 0 1px;
  color: #ffffff;
  font-size: 15px;
  height: 57px;
  text-transform: capitalize;
  width: 100%;
  background: none;
  transition: 0.3s;
  font-family: "cerebri_sansmedium";
}

#contact-form input::-moz-placeholder, .contact-form input::placeholder {
  color: #ffffff;
  font-size: 15px;
  font-family: "cerebri_sansmedium";
}

#contact-form input:focus {
  border-color: #ffffff;
  border-bottom: 2px solid #fff;
}

#contact-form textarea::-moz-placeholder, .contact-form textarea::placeholder {
  color: #ffffff;
  font-size: 15px;
  font-family: "cerebri_sansmedium";
}

#contact-form textarea:focus {
  border-color: #ffffff;
  border-bottom: 2px solid #fff;
}

#contact-form button.btn {
  -moz-user-select: none;
  background: #fff;
  border: medium none;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 0;
  padding: 20px 40px;
  text-align: center;
  text-transform: uppercase;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 5px;
  position: relative;
  font-family: "cerebri_sanssemibold";
  margin-top: 5px;
}

.btn-texts {
  color: #333333;
  position: relative;
  transition: 0.3s;
}

#contact-form button.btn:hover .btn-text {
  color: #ffffff;
}

.btn-texts i {
  padding-left: 5px;
}

#contact-form button.btn::before {
  display: none;
}

.form-2-box {
  position: relative;
}

.form-2-box::after {
  position: absolute;
  content: "";
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
  font-size: 16px;
  text-align: center;
  right: 0;
  color: #ffffff;
  top: 20px;
}

.email-2-icon:after {
  content: "";
}

.sub-icon:after {
  content: "";
}

.message-2-icon::after {
  content: "";
  top: 9px;
}

.contact-us-area {
  margin-bottom: -567px;
  position: relative;
  z-index: 99;
}

.contact-2-map {
  min-height: 700px;
}

.single-contact-us {
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-position: center center;
  right: 0;
}

.contact-us-info {
  background: #ffffff;
  padding: 37px 100px 37px 54px;
}

.contact-us-icon {
  float: left;
  margin-right: 20px;
}

.contact-us-text {
  overflow: hidden;
}
.contact-us-text span {
  color: #ababab;
  font-size: 16px;
  font-family: "cerebri_sansbold";
}
.contact-us-text h4 {
  font-size: 22px;
  margin-bottom: 0;
  margin-top: 4px;
}

.contact-us-bg {
  background: #096bd8;
}

.contact-us-wrapper {
  padding: 55px 70px 70px 40px;
}

.contact-us-content h1 {
  color: #ffffff;
  font-size: 45px;
  margin-bottom: 40px;
}

#contact-us-form input {
  background: none;
  height: 60px;
  width: 100%;
  padding-left: 20px;
  border: 0;
  color: #ffffff;
  font-family: "cerebri_sansregular";
  border: 1px solid #328ef4;
  font-size: 15px;
  text-transform: capitalize;
  border-radius: 5px;
  margin-bottom: 30px;
}

#contact-us-form textarea {
  color: #ffffff;
  height: 120px;
  width: 100%;
  padding: 30px 30px;
  border: 1px solid #328ef4;
  text-transform: capitalize;
  background: none;
  border-radius: 5px;
  font-family: "cerebri_sansregular";
  margin-bottom: 24px;
}

#contact-us-form input::-moz-placeholder, .contact-us-form input::placeholder {
  color: #ffffff;
  font-size: 15px;
}

#contact-us-form textarea::-moz-placeholder, #contact-us-form textarea::placeholder {
  color: #ffffff;
  font-size: 15px;
}

#contact-us-form button.btn {
  -moz-user-select: none;
  background: #fff;
  border: medium none;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 0;
  padding: 20px 40px;
  text-align: center;
  text-transform: uppercase;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 5px;
  position: relative;
  font-family: "cerebri_sanssemibold";
  margin-top: 5px;
}

.btn-texts {
  color: #333333;
  position: relative;
  transition: 0.3s;
}

.btn-texts i {
  padding-left: 5px;
}

#contact-us-form button.btn::before {
  display: none;
}

.contact-2-area {
  background-size: cover;
  background-position: center center;
}

.appointment-form input {
  height: 70px;
  width: 100%;
  padding: 0 30px;
  padding-right: 30px;
  border: 0;
  color: #a9bbc8;
  font-family: "cerebri_sansregular";
  border: 2px solid #566875;
  font-size: 15px;
  text-transform: capitalize;
  border-radius: 5px;
  background: rgba(59, 69, 82, 0.6);
  transition: 0.3s;
}

.appointment-form input:focus {
  border-color: #ffffff;
  border-bottom: 2px solid #fff;
  background: none;
}

.appointment-form textarea {
  color: #a9bbc8;
  font-family: "cerebri_sansregular";
  height: 120px;
  width: 100%;
  padding: 17px 30px;
  border: 2px solid #566875;
  text-transform: capitalize;
  background: rgba(59, 69, 82, 0.6);
  border-radius: 5px;
  transition: 0.3s;
}

.appointment-form textarea:focus {
  border-color: #ffffff;
  border-bottom: 2px solid #fff;
  background: none;
}

.appointment-form input:focus::-moz-placeholder, .appointment-form input:focus::placeholder {
  color: #ffffff;
}

.appointment-form input::-moz-placeholder, .appointment-form input::placeholder {
  color: #a9bbc8;
  font-size: 15px;
  font-family: "cerebri_sansregular";
}

.appointment-form textarea::-moz-placeholder, .appointment-form textarea::placeholder {
  color: #a9bbc8;
  font-size: 15px;
  font-family: "cerebri_sansregular";
}

.appointment-form textarea:focus::-moz-placeholder, .appointment-form textarea:focus::placeholder {
  color: #ffffff;
}

.appointment-form .form-box::after {
  color: #ffffff;
  top: 24px;
}

.contact-2-btn .btn {
  padding: 21px 90px;
}

/* 20. Contact */
.contact-page-map {
  height: 750px;
}

.contact-info-area {
  margin-top: -140px;
}

.contact {
  padding: 70px 10px;
}

.contact-area {
  background-position: center center;
  background-repeat: no-repeat;
}

.contact i {
  background: #096bd8;
  font-size: 21px;
  height: 70px;
  width: 70px;
  color: #ffffff;
  border-radius: 50%;
  line-height: 71px;
  margin-bottom: 30px;
}

.contact h3 {
  font-size: 25px;
  margin-bottom: 15px;
  color: #fff;
}

.contact p {
  margin: 0;
  padding: 0 50px;
  color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .contact p {
    padding: 0 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact p {
    padding: 0 0px;
  }
}
.contact-form-page .appointment-form input {
  border: 2px solid transparent;
  text-transform: capitalize;
  background: #f4f4f4;
  font-size: 15px;
  color: #696969;
}

.contact-form-page .appointment-form input:-moz-placeholder {
  color: #696969;
}

.contact-form-page .appointment-form input::placeholder {
  color: #696969;
}

.contact-form-page .appointment-form textarea {
  border: 2px solid transparent;
  text-transform: capitalize;
  background: #f4f4f4;
  font-size: 15px;
  color: #696969;
}

.contact-form-page .appointment-form .form-box::after {
  color: #096bd8;
}

.contact-form-page.appointment-form input:focus::-moz-placeholder, .appointment-form input:focus::placeholder {
  color: #696969;
}

.contact-form-page .appointment-form input:focus, .appointment-form input:focus {
  border-color: #096bd8;
}

.contact-form-page .appointment-form textarea:focus::-moz-placeholder, .appointment-form textarea:focus::placeholder {
  color: #696969;
}

.contact-form-page .appointment-form textarea:focus, .appointment-form textarea:focus {
  border-color: #096bd8;
}

.contact-form-page .appointment-form textarea::-moz-placeholder, .appointment-form textarea::placeholder {
  color: #696969;
}

.contact-form-page .appointment-form textarea:focus, .appointment-form textarea:focus {
  border-color: #096bd8;
}

input:focus:-moz-placeholder {
  opacity: 0;
}

input:focus::placeholder {
  opacity: 0;
}

textarea:focus:-moz-placeholder {
  opacity: 0;
}

textarea:focus::placeholder {
  opacity: 0;
}

/* carrer  */
.carrer-cat {
  background: #f76840;
  padding: 40px 15px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.carrer-cat:hover {
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
}

.carrer-cat h3 {
  color: #fff;
  font-size: 24px;
  margin: 0;
}

.carrer-cat img {
  margin-bottom: 20px;
}

.job-list {
  background: #fff;
  padding: 40px;
  padding-top: 35px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.job-list:hover {
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
}

.job-list h3 {
  font-size: 24px;
  margin-bottom: 15px;
  display: inline-block;
}

.blog-img a img {
  width: 100%;
}

.blog-meta {
  box-shadow: 0px 10px 32px 0px rgba(197, 197, 197, 0.34);
  background: #fff;
  padding: 13px 30px 13px;
  margin-left: 20px;
  margin-right: 35px;
  border-radius: 6px;
  margin-top: -22px;
  position: relative;
}
.blog-meta span {
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 400;
  margin-right: 14px;
}
.blog-meta span:last-child {
  margin-right: 0;
}
.blog-meta span i {
  color: #096bd8;
  margin-right: 3px;
}

.blog-text h3 {
  font-size: 24px;
  font-family: "cerebri_sansbold";
  margin-top: 32px;
  margin-bottom: 19px;
}
.blog-text h3 > a {
  color: #333333;
  transition: 0.3s;
}
.blog-text h3 > a:hover {
  color: #096bd8;
}
.blog-text p {
  margin-bottom: 0;
  line-height: 30px;
}

.blog-text > a {
  position: relative;
  display: inline-block;
  margin-top: 27px;
  font-family: "cerebri_sanssemibold";
  text-transform: uppercase;
  font-size: 14px;
  padding: 12px 37px;
  transition: 0.3s;
  border-radius: 5px;
  vertical-align: middle;
  border: 2px solid #096bd8;
}

.blog-button {
  color: #096bd8;
  z-index: 999;
  position: relative;
  transition: 0.3s;
}

.blog-button i {
  margin-left: 5px;
}

.blog-text > a:hover .blog-button {
  color: #ffffff;
}

.blog-text > a:hover {
  border-color: #1065c4;
}

.blog-text > a::before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  background: #096bd8;
  -webkit-transition: all 0.3s ease;
  transition: all 0.4s ease;
}

.blog-text > a:hover::before {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.blog-border {
  border: 1px solid #ebebeb;
}

.blog-border .blog-meta {
  box-shadow: none;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  border-radius: 0;
  margin-top: 0;
}

.blog-border .blog-text h3 {
  margin-top: 20px;
  margin-bottom: 19px;
}

.single-blog {
  padding: 32px 26px 20px 0;
}

.blog-2-wrapper .blog-meta {
  box-shadow: none;
  background: none;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  border-radius: 0;
  margin-top: 0;
  position: inherit;
  margin-top: 29px;
}

.blog-2-wrapper .blog-text h3 {
  margin-top: 14px;
}

/* 7.blog */
.latest-news {
  position: relative;
}

.news__thumb:before {
  background-image: -moz-linear-gradient(90deg, #062a4d 0%, rgba(0, 0, 0, 0) 100%);
  background-image: -webkit-linear-gradient(90deg, #062a4d 0%, rgba(0, 0, 0, 0) 100%);
  background-image: -ms-linear-gradient(90deg, #062a4d 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
}
.news__thumb img {
  width: 100%;
}

.news__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 45px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .news__caption {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .news__caption {
    padding: 20px;
  }
}
.news__caption h2 {
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: -0.2px;
}
.news__caption h2 a {
  color: #ffffff;
}
.news__caption h2 a:hover {
  opacity: 0.7;
}

.news-link {
  font-size: 12px;
  color: #e4ecf3;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.news-link:hover {
  color: #ffffff;
}

.news-link i {
  transition: 0.3s;
  display: inline-block;
}

.news-link:hover i {
  transform: translateX(3px);
}

.subscribe {
  padding: 70px 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe {
    padding: 30px 50px;
  }
}
@media (max-width: 767px) {
  .subscribe {
    padding: 30px 30px;
  }
}

.subscribe-form {
  position: relative;
  z-index: 99;
}

.subscribe-1 {
  position: relative;
}

.subscribe-1::before {
  position: absolute;
  background-image: url(../img/icon/envelope.html);
  content: "";
  height: 326px;
  width: 322px;
  right: 0px;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
  top: 0;
  background-size: cover;
}

.subscribe-form form {
  position: relative;
}
.subscribe-form form input {
  width: 100%;
  height: 70px;
  border: 0;
  padding: 0 50px;
  text-transform: uppercase;
  font-weight: 500;
}
@media (max-width: 767px) {
  .subscribe-form form input {
    padding: 0 30px;
  }
}
.subscribe-form form button {
  position: absolute;
  top: 5px;
  right: 5px;
}
@media (max-width: 767px) {
  .subscribe-form form button {
    position: static;
    margin-top: 10px;
  }
}

.subscribe-form input::-moz-placeholder, .subscribe-form input::placeholder {
  color: #7c9ebf;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-2 {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .subscribe-form-2 {
    margin-top: 30px;
  }
}

.letter-shape {
  position: absolute;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .letter-shape {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .letter-shape {
    display: none;
  }
}
@media (max-width: 767px) {
  .letter-shape {
    display: none;
  }
}

.letter-s1 {
  left: 6%;
  top: 15%;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .letter-s1 {
    left: 1%;
    top: 9%;
  }
}

.letter-s2 {
  right: 8%;
  bottom: 10%;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .letter-s2 {
    right: 3%;
    bottom: 4%;
  }
}

.news__thumb-2 img {
  width: 100%;
}

.news__caption-2 {
  padding: 33px;
}
.news__caption-2 h2 {
  font-size: 20px;
  margin-bottom: 15px;
}
.news__caption-2 h2 a:hover {
  color: #096bd8;
}
.news__caption-2 p {
  margin-bottom: 0;
}

.news-meta span {
  text-transform: uppercase;
  margin-right: 25px;
  font-size: 13px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .news-meta span {
    margin-right: 10px;
  }
}
.news-meta span a {
  color: #096bd8;
  letter-spacing: 2px;
  font-weight: 500;
}
.news-meta span a:hover {
  color: #6100b3;
}

.postbox__thumb {
  position: relative;
}
.postbox__thumb a img {
  max-width: 100%;
}

.post-meta span {
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 500;
  text-transform: uppercase;
  color: #696969;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .post-meta span {
    margin-right: 10px;
  }
}
.post-meta span a:hover {
  color: #096bd8;
}
.post-meta span i {
  color: #096bd8;
  margin-right: 2px;
}

.postbox__video img, .postbox__thumb img {
  width: 100%;
}

.p-30 {
  padding: 30px;
}

.blog-title {
  font-size: 35px;
}
.blog-title a:hover {
  color: #096bd8;
}

.read-more {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
}
.read-more:hover {
  color: #096bd8;
}
.read-more i {
  transition: 0.3s;
  display: inline-block;
}
.read-more:hover i {
  transform: translateX(5px);
}

.blog-title-sm {
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 1.5;
}

.post-text p {
  font-size: 16px;
  line-height: 28px;
}

.postbox__gallery .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  border: none;
  background: none;
  padding: 0;
  z-index: 9;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background: #ffffff;
  width: 60px;
  height: 60px;
  color: #696969;
  border-radius: 50%;
  font-size: 14px;
}
.postbox__gallery .slick-arrow:hover {
  background: #096bd8;
  color: #ffffff;
}

.postbox__gallery .slick-arrow.slick-next {
  right: 30px;
  left: auto;
}

.postbox__video {
  position: relative;
}

.video-btn {
  position: absolute;
  background: #ffffff;
  height: 120px;
  width: 120px;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  border-radius: 50%;
  line-height: 120px;
  color: #096bd8;
  transform: translateY(-50%);
}
.video-btn:hover {
  background: #096bd8;
  color: #ffffff;
}

.post-text blockquote {
  background: #333333;
  padding: 50px;
  position: relative;
  padding-left: 120px;
}
@media (max-width: 767px) {
  .post-text blockquote {
    padding: 30px;
  }
}
.post-text blockquote p {
  font-size: 26px;
  font-family: "cerebri_sansbold";
  margin-bottom: 20px;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 1.3;
}
.post-text blockquote::before {
  content: "";
  left: 50px;
  top: 50px;
  position: absolute;
  color: #758799;
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  font-size: 41px;
}
@media (max-width: 767px) {
  .post-text blockquote::before {
    position: static;
  }
}
.post-text blockquote footer {
  font-size: 14px;
  letter-spacing: 2px;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
}

.widget {
  border: 2px solid #eaedff;
  padding: 30px;
}

.b-0 {
  border: 0;
}

.search-form {
  position: relative;
}
.search-form input {
  width: 100%;
  height: 60px;
  border: 0;
  background: #eaedff;
  padding: 0 20px;
}
.search-form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: #333333;
  padding: 0 25px;
  color: #ffffff;
  line-height: 60px;
  border: 0;
}
.search-form button:hover {
  background: #096bd8;
}

.widget-title {
  font-size: 21px;
  margin-bottom: 0px;
  position: relative;
  display: inline-block;
}

.animate-border::after {
  position: absolute;
  content: "";
  width: 35px;
  height: 3px;
  left: 0;
  bottom: 0;
  border-left: 10px solid #ffffff;
  border-right: 10px solid #ffffff;
  -webkit-animation: animborder 2s linear infinite;
  animation: animborder 2s linear infinite;
}

.animate-border.border-white::after {
  border-color: #ffffff;
}

.animate-border.border-white::after {
  border-color: #ffffff;
}

.animate-border {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 4px;
  background: #096bd8;
  margin-right: 10px;
  top: -3px;
}

.animate-border::after {
  position: absolute;
  content: "";
  width: 35px;
  height: 4px;
  left: 5%;
  bottom: 0;
  border-left: 10px solid #ffffff;
  border-right: 10px solid #ffffff;
}

@-webkit-keyframes animborder {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(25px);
    transform: translateX(25px);
  }
}
@keyframes animborder {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(25px);
    transform: translateX(25px);
  }
}
.about-me img {
  margin-bottom: 25px;
}
.about-me h4 {
  font-size: 18px;
  letter-spacing: 1px;
}
.widget-social-icon a {
  display: inline-block;
  margin: 0 8px;
}
.widget-social-icon a:hover {
  color: #096bd8;
}

.widget .recent-posts > li:first-child {
  border: 0;
  padding-top: 0;
}

.widget .recent-posts li:last-child {
  padding-bottom: 0;
}

.widget .recent-posts > li {
  border-top: 1px solid #eaedff;
  padding: 20px 0;
  overflow: hidden;
}

.widget-posts-image {
  float: left;
}
.widget-posts-image img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 15px;
}

.widget-posts-body {
  overflow: hidden;
}

.widget-posts-title {
  margin-bottom: 10px;
  font-size: 16px;
}
.widget-posts-title:hover {
  color: #096bd8;
}

.widget-posts-meta {
  font-size: 12px;
}

ul.cat li {
  border-top: 1px solid #eaedff;
  padding: 15px 0;
  overflow: hidden;
}
ul.cat li:first-child {
  border-top: 0;
  padding-top: 0;
}
ul.cat li:last-child {
  padding-bottom: 0;
}
ul.cat li a {
  display: block;
}
ul.cat li a:hover {
  color: #096bd8;
}

.social-profile a {
  height: 50px;
  width: 50px;
  text-align: center;
  background: #333333;
  line-height: 50px;
  margin-right: 2px;
  display: inline-block;
  color: #ffffff;
  margin-bottom: 5px;
}
.social-profile a:hover {
  background: #096bd8;
}

#Instafeed {
  margin: 0 -5px;
  overflow: hidden;
}
#Instafeed li {
  width: 33.33%;
  float: left;
  padding: 0 5px;
  margin-bottom: 10px;
}
#Instafeed li img {
  width: 100%;
}

.tag a {
  display: inline-block;
  line-height: 1;
  padding: 10px 15px;
  background: #eaedff;
  margin-bottom: 8px;
  margin-right: 5px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
.tag a:hover {
  background: #096bd8;
  color: #ffffff;
}

.bg-none {
  background: none;
}

.blog-post-tag span {
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin-bottom: 17px;
  font-family: "cerebri_sansbold";
  color: #333333;
}

.blog-post-tag a {
  color: #646974;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  border: 2px solid #eaedff;
  padding: 12px 24px;
  margin-right: 10px;
  transition: 0.3s;
  border-radius: 5px;
  margin-bottom: 10px;
}
.blog-post-tag a:hover {
  color: #fff;
  background: #096bd8;
  border-color: #096bd8;
}
.blog-post-tag a:last-child {
  margin-right: 0;
}

.blog-share-icon span {
  font-size: 18px;
  font-weight: 600;
  font-family: "cerebri_sansbold";
  color: #333333;
  display: block;
  margin-bottom: 17px;
}

.blog-share-icon a {
  color: #696969;
  font-size: 18px;
  transition: 0.3s;
  margin-right: 22px;
}
.blog-share-icon a:hover {
  color: #096bd8;
}
.blog-share-icon a:last-child {
  margin-right: 0;
}

.blog-details-border {
  border-top: 1px solid #eaedff;
  border-bottom: 1px solid #eaedff;
  padding-top: 33px;
  padding-bottom: 23px;
  margin-bottom: 42px;
}

.author {
  background: #eaedff;
  padding: 0 70px 44px 70px;
}
@media (max-width: 767px) {
  .author {
    padding: 0 30px 44px 30px;
  }
}

.author-img {
  margin-bottom: 35px;
}
.author-img img {
  margin-top: -60px;
}

.author-text h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}
.author-text p {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 28px;
}

.author-icon {
  margin-top: 6px;
  margin-bottom: 25px;
}
.author-icon a {
  font-size: 14px;
  color: #9498b6;
  margin: 0 5px;
  transition: 0.3s;
}
.author-icon a:hover {
  color: #096bd8;
}

.post-comments {
  border-bottom: 1px solid #eaedff;
  margin-bottom: 42px;
  padding-bottom: 46px;
}

.blog-coment-title h2 {
  font-size: 26px;
  margin-bottom: 0;
}

.comments-avatar {
  float: left;
}
@media (max-width: 767px) {
  .comments-avatar {
    float: none;
  }
}
.comments-avatar img {
  width: 100px;
  margin-right: 20px;
}

.latest-comments li:first-child .comments-box {
  border-top: 0;
  padding-top: 0;
}
.latest-comments li:last-child .comments-box {
  border-bottom: 0;
  padding-bottom: 0;
}
.latest-comments li.children {
  margin-left: 100px;
}
@media (max-width: 767px) {
  .latest-comments li.children {
    margin-left: 0;
  }
}

.comments-box {
  border-top: 1px solid #eaedff;
  padding: 30px 0;
}

.comments-text {
  overflow: hidden;
  padding-top: 4px;
}
@media (max-width: 767px) {
  .comments-text {
    margin-top: 15px;
  }
}
.comments-text p {
  margin-bottom: 0;
  margin-top: 8px;
}

.avatar-name h5 {
  font-size: 18px;
  margin-bottom: 7px;
}
.avatar-name span {
  color: #096bd8;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.avatar-name {
  margin-bottom: 3px;
  overflow: hidden;
  position: relative;
}

.reply {
  color: #696969;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  transition: 0.3s;
  line-height: 1;
  float: right;
  margin-top: 4px;
  position: absolute;
  right: 0;
  top: 0;
}
.reply:hover {
  color: #096bd8;
}
.reply i {
  margin-right: 5px;
}

.post-comments-title {
  margin-bottom: 39px;
}
.post-comments-title h2 {
  font-size: 26px;
  font-weight: 700;
  color: #140a20;
  margin-bottom: 0;
}

.conatct-post-form {
  background: #eaedff;
  padding: 50px;
}
@media (max-width: 767px) {
  .conatct-post-form {
    padding: 20px;
  }
}
.conatct-post-form input {
  border: none;
  height: 60px;
  margin-bottom: 20px;
  padding: 0 30px;
  width: 100%;
  font-size: 14px;
  color: #a7a7c1;
}
.conatct-post-form textarea {
  border: none;
  height: 150px;
  margin-bottom: 20px;
  padding: 30px;
  width: 100%;
  color: #a7a7c1;
  font-size: 14px;
}

#contacts-form input::-moz-placeholder {
  color: #a7a7c1;
  font-size: 14px;
}

#contacts-form input::placeholder {
  color: #a7a7c1;
  font-size: 14px;
}

#contacts-form textarea::-moz-placeholder {
  color: #a7a7c1;
  font-size: 14px;
}

#contacts-form textarea::placeholder {
  color: #a7a7c1;
  font-size: 14px;
}

.contact-icon {
  position: relative;
}

.contact-icon::after {
  position: absolute;
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  right: 30px;
  color: #096bd8;
  top: 18px;
}

.contacts-message::after {
  content: "";
}

.contacts-name::after {
  content: "";
}

.contacts-email::after {
  content: "";
}

.contacts-website::after {
  content: "";
}

.brand-active.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

.brand-img.text-center {
  text-align: left;
}

.newsletter-area {
  background-size: cover;
  background-position: center center;
}

.single-newsletters {
  background: #fff;
  padding: 20px;
}

.newsletter-form form {
  position: relative;
}

.newsletter-form form input {
  height: 60px;
  padding: 0 30px;
  background: #eef5fc;
  color: #696969;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  width: 340px;
}

.newsletter-form form input::placeholder {
  color: #696969;
  font-size: 15px;
}

.newsletter-form form::-moz-placeholder {
  color: #696969;
  font-size: 15px;
}

.newsletter-form button.btn {
  margin-left: 7px;
}

.newsletter-text h1 {
  color: #fff;
  margin-bottom: 0;
  font-size: 35px;
}

.single-newsletter .newsletter-form form input {
  height: 60px;
  padding: 0 30px;
  background: #1b2f3e;
  color: #bababa;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  width: 340px;
  float: left;
}

.single-newslette .newsletter-form form input::placeholder {
  color: #bababa;
  font-size: 15px;
}

.single-newslette .newsletter-form form::-moz-placeholder {
  color: #bababa;
  font-size: 15px;
}

.single-newsletter .newsletter-form button.btn {
  margin-left: 7px;
  padding: 19px 40px;
}

.newsletter-bg {
  border-bottom: 1px solid #1b2f3e;
}

.newsletter-2-bg {
  padding-left: 50px;
  padding-right: 50px;
  background-size: cover;
  background-position: center center;
}

.newsletter-2-bg .single-newsletters {
  background: none;
  padding: 0;
  margin-top: 11px;
}

.newsletter-2-bg .newsletter-form form input {
  height: 60px;
  padding: 0 25px;
  background: #0056b6;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  width: 324px;
}

.newsletter-2-bg .newsletter-form form input::placeholder {
  color: #ffffff;
  font-size: 15px;
}

.newsletter-2-bg .newsletter-form form::-moz-placeholder {
  color: #ffffff;
  font-size: 15px;
}

.newsletter-2-bg .newsletter-form form .btn {
  background: #ffffff;
}

.newsletter-2-bg .newsletter-form form .btn .btn-text {
  color: #096bd8;
}

.newsletter-2-bg .newsletter-form form .btn::before {
  display: none;
}

.faq-box .btn-link {
  font-family: "cerebri_sanssemibold";
  color: #696969;
  display: block;
  font-size: 18px;
  border: 0;
  text-decoration: none;
  line-height: 28px;
  padding-left: 24px;
}

.faq-box .card-header {
  padding: 0;
  border-bottom: 0;
  background: none;
  border-radius: 0;
}

.faq-box .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0;
  border-radius: 0;
  background: none;
  margin-bottom: 19px;
}

.faq-box .card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0 0 0 0;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 23px;
  border-top: 1px solid #e6e6e6;
  padding-top: 15px;
  margin-top: 12px;
}

.faq-box .card-body p {
  margin: 0;
  line-height: 28px;
}

.faq-box .btn-link.collapsed::before {
  content: "";
}

.faq-box .btn-link::before {
  content: "";
  line-height: 30px;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 15px;
  font-family: "Font Awesome 5 Pro";
  color: #696969;
}

.faq-wrapper .section-title p {
  margin-top: 21px;
}

.frequently-area {
  background-size: cover;
  background-position: center center;
}

.frequently-text li {
  background: #fff;
  padding: 33px 51px 34px 50px;
  margin-right: 70px;
  margin-bottom: 20px;
}
.frequently-text li:last-child {
  margin-bottom: 0;
}

.frequently-content h4 {
  font-size: 20px;
  margin-bottom: 15px;
}
.frequently-content p {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 30px;
}

.frequently-video {
  margin-top: 115px;
}
.frequently-video a {
  height: 80px;
  width: 80px;
  background: #ffffff;
  display: inline-block;
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  font-size: 16px;
  transition: 0.3s;
  color: #096bd8;
  position: relative;
}

.choose-area {
  background-size: cover;
  background-position: center center;
}

.choose-bg {
  background: #ffffff;
  margin-left: 286px;
  margin-right: 286px;
  padding-top: 86px;
  padding-bottom: 58px;
}

.faq-2-box .btn-link::before {
  content: "";
}

.faq-2-box .btn-link.collapsed::before {
  content: "";
}

.choose-img {
  position: relative;
}
.choose-img img {
  width: 100%;
}

.choose-video {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}
.choose-video a {
  height: 65px;
  width: 65px;
  background: #ffffff;
  display: inline-block;
  border-radius: 50%;
  line-height: 65px;
  text-align: center;
  font-size: 14px;
  transition: 0.3s;
  color: #096bd8;
  position: relative;
}

.choose-text p {
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 51px;
}

@-webkit-keyframes btnIconRipple {
  0% {
    border-width: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  80% {
    border-width: 1px;
    -webkit-transform: scale(1.35);
    transform: scale(1.35);
  }
  100% {
    opacity: 0;
  }
}
.choose-video a::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: -2px;
  left: -2px;
  bottom: -2px;
  right: -2px;
  border-radius: inherit;
  border: 1px solid #fff;
  -webkit-animation: btnIconRipple 2s cubic-bezier(0.23, 1, 0.32, 1) both infinite;
  animation: btnIconRipple 2s cubic-bezier(0.23, 1, 0.32, 1) both infinite;
}

.working-process-area {
  background-size: cover;
  background-position: center center;
}

.working-process-icon {
  margin-bottom: 30px;
  position: relative;
}
.working-process-icon i {
  color: #ffffff;
  font-size: 60px;
  height: 140px;
  width: 140px;
  line-height: 140px;
  background: #318bf0;
  border-radius: 5px;
}

.angle-icon {
  position: absolute;
  top: 42px;
  right: -162px;
}

.working-process-text h3 {
  color: #ffffff;
  font-size: 24px;
  font-family: "cerebri_sansbold";
  margin-bottom: 10px;
}
.working-process-text p {
  color: #ffffff;
  margin-bottom: 0;
  line-height: 30px;
}

.wor-01 {
  bottom: 6%;
  right: 4%;
}

.wor-02 {
  bottom: 15%;
  left: 6%;
}

.work-process-wrapper {
  border: 2px solid #f1f1f1;
  padding: 74px 45px 66px 45px;
  position: relative;
  transition: 0.3s;
}

.work-process-wrapper::before {
  background-image: url("../img/bg/work.png");
  background-repeat: no-repeat;
  content: "";
  height: 100%;
  position: absolute;
  transition: all 0.3s ease 0s;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  background-size: cover;
}

.work-process-wrapper:hover::before {
  opacity: 1;
}

.work-process-wrapper.active::before {
  opacity: 1;
}

.work-process-wrapper:hover {
  border-color: #096bd8;
}

.work-process-wrapper.active {
  border-color: #096bd8;
}

.inner-content {
  position: relative;
}

.work-process-icon {
  margin-bottom: 30px;
}

.work-process-text h3 {
  font-size: 24px;
  font-family: "cerebri_sansbold";
  margin-bottom: 11px;
  transition: 0.3s;
}
.work-process-text p {
  margin-bottom: 0;
  line-height: 30px;
  transition: 0.3s;
}

.work-process-wrapper:hover .work-process-text h3 {
  color: #ffffff;
}

.work-process-wrapper:hover .work-process-text p {
  color: #ffffff;
}

.work-process-wrapper.active .work-process-text h3 {
  color: #ffffff;
}

.work-process-wrapper.active .work-process-text p {
  color: #ffffff;
}

.progress-bar-text {
  background: #091b29;
  padding: 90px 117px 90px 100px;
  margin-left: -150px;
}

.progress-skill {
  overflow: hidden;
}

.our-skills-img {
  margin-right: 20px;
}

.our-skills-img img {
  width: 100%;
}

.bar-title h4 {
  color: #ffffff;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 15px;
  font-family: "cerebri_sansbold";
}

.progress-bar span {
  color: #ffffff;
  font-size: 16px;
  margin-top: -50px;
  font-family: "cerebri_sansbold";
}

.progress-bar {
  background-color: #ff9900;
  text-align: right;
}

.progress {
  border-radius: 0;
  height: 5px;
  overflow: visible;
  background: #173044;
}

.progress-bar-text .single-skill:nth-child(1) .progress-bar {
  background: #ff9900;
}

.progress-bar-text .single-skill:nth-child(2) .progress-bar {
  background: #096bd8;
}

.progress-bar-text .single-skill:nth-child(3) .progress-bar {
  background: #fc4557;
}

.progress-bar-text .single-skill:nth-child(4) .progress-bar {
  background: #1cb5a3;
}

.progress-bar-text .single-skill:nth-child(4) .progress-bar {
  background: #ff266f;
}

.fact-are-area {
  background-size: cover;
  background-position: center center;
}

.circular-progress {
  position: relative;
  display: inline-block;
  text-align: center;
  background: #227adc;
  border-radius: 50%;
}

.circular-progress canvas {
  height: 150px;
  width: 150px;
}

.circular-progress span {
  position: absolute;
  text-align: center;
  color: #fff;
  font-size: 55px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.progress-wrapper {
  text-align: center;
}

.progress-content {
  margin-top: 18px;
  text-align: center;
}
.progress-content h1 {
  color: #fff;
  font-size: 45px;
  margin-bottom: 4px;
}
.progress-content span {
  color: #fff;
  font-size: 18px;
  font-family: "cerebri_sansmedium";
}

.fact-text span {
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "cerebri_sansbold";
}
.fact-text h1 {
  color: #ffffff;
  font-size: 45px;
  margin-top: 20px;
  margin-bottom: 40px;
}
.fact-text a {
  color: #333333;
  -moz-user-select: none;
  background: #ffffff;
  border: medium none;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 0;
  padding: 20px 40px;
  text-align: center;
  text-transform: uppercase;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 5px;
  font-family: "cerebri_sanssemibold";
}
.fact-text a i {
  margin-left: 5px;
}

.skill-bg {
  background: #091b29;
  padding: 73px 100px 50px 100px;
}

.skill-bg .progress-bar-text {
  background: none;
  padding: 0;
  margin-left: 0;
}

.progress-bar-2-text .single-skill:nth-child(1) .progress-bar {
  background: #1cb5a3;
}

.progress-bar-2-text .single-skill:nth-child(2) .progress-bar {
  background: #ff266f;
}

.progress-bar-2-text .single-skill:nth-child(3) .progress-bar {
  background: #e124e3;
}

.experience-text h1 {
  font-size: 45px;
  margin-bottom: 21px;
}

.experience-text p {
  margin-right: 91px;
}

.experience-text:last-child {
  margin-bottom: 0;
}

.experience-img {
  position: relative;
}

.experience-img img {
  width: 100%;
}

.experience-video {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
}

.experience-video a {
  height: 60px;
  width: 60px;
  background: #ffffff;
  display: inline-block;
  border-radius: 50%;
  line-height: 60px;
  text-align: center;
  font-size: 14px;
  color: #096bd8;
  position: relative;
  transition: 0.3s;
}

.experience-video a:hover {
  background: #096bd8;
  color: #ffffff;
}

.singles-progress .progress-bar-text {
  background: #091b29;
  padding: 70px 70px 80px 70px;
  margin-left: 0;
}

.pricing-area {
  background-size: cover;
  background-position: center center;
}

.pricing-wrapper {
  background: #ffffff;
  position: relative;
  border-radius: 5px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.pricing-icon {
  margin-bottom: 22px;
}

.price-count h1 {
  font-size: 35px;
  line-height: 1;
  margin-bottom: 18px;
  font-family: "cerebri_sansbold";
  color: #096bd8;
}

.price-count h1 span.usd {
  position: relative;
  right: 2px;
}

.pricing-top-text h3 {
  font-size: 24px;
  text-transform: uppercase;
  color: #096bd8;
  font-family: "cerebri_sansbold";
}

.pricing-menu {
  border: 1px solid #e7e7e7;
  margin: 36px 40px;
  padding: 20px 0;
  transition: 0.3s;
}

.pricing-wrapper.active .pricing-menu {
  border-color: #096bd8;
}

.pricing-wrapper:hover .pricing-menu {
  border-color: #096bd8;
}

.pricing-menu ul li {
  color: #808080;
  font-size: 16px;
  padding-bottom: 16px;
  font-family: "cerebri_sansregular";
}
.pricing-menu ul li:last-child {
  padding-bottom: 0;
}

.pricing-button a {
  position: relative;
  display: inline-block;
  margin-top: 24px;
  font-family: "cerebri_sanssemibold";
  text-transform: uppercase;
  font-size: 14px;
  padding: 13px 29px;
  transition: 0.3s;
  border-radius: 5px;
  vertical-align: middle;
  border: 2px solid #ececec;
}

.pricings-button {
  color: #333333;
  z-index: 999;
  position: relative;
  transition: 0.3s;
}

.pricings-button i {
  margin-left: 5px;
}

.pricing-button a:hover .pricings-button {
  color: #ffffff;
}

.pricing-button a:hover {
  border-color: #1065c4;
}

.pricing-button.active a .pricings-button {
  color: #ffffff;
}

.pricing-button.active a {
  border-color: #1065c4;
}

.pricing-button a::before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  background: #096bd8;
  -webkit-transition: all 0.3s ease;
  transition: all 0.4s ease;
}

.pricing-button a:hover::before {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.pricing-button.active a::before {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.pricing-tag {
  background: #096bd8;
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 1px 9px 2px;
  border-radius: 5px;
}

.pricing-tag span {
  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "cerebri_sansbold";
}

.single-pricing {
  border: 1px solid #e7e7e7;
}

.breadcrumb-area {
  background-position: center center;
  background-size: cover;
}

.breadcrumb-text {
  position: relative;
  z-index: 1;
}

.breadcrumb-text > h1 {
  font-size: 60px;
  margin-bottom: 20px;
  text-transform: capitalize;
  line-height: 1;
  font-family: "cerebri_sansbold";
  color: #ffffff;
}

.breadcrumb-menu li {
  display: inline-block;
  position: relative;
  padding: 0 19px;
}

.breadcrumb-menu li a {
  color: #096bd8;
  font-size: 20px;
  font-family: "cerebri_sansregular";
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}

.breadcrumb-menu li::before {
  content: "||";
  display: inline-block;
  padding-bottom: 0;
  position: absolute;
  top: 4px;
  color: #ffffff;
  left: -7px;
}

.breadcrumb-menu li:first-child::before {
  display: none;
}

.breadcrumb-menu span {
  color: #ffffff;
  font-size: 20px;
  font-family: "cerebri_sansregular";
  text-transform: capitalize;
}

.basic-pagination ul {
  display: block;
}
.basic-pagination ul li {
  display: inline-block;
}
@media (max-width: 767px) {
  .basic-pagination ul li {
    margin-bottom: 10px;
  }
}
.basic-pagination ul li a {
  height: 70px;
  width: 70px;
  background: #ffffff;
  color: #9990b8;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50%;
  line-height: 70px;
  margin: 0 5px;
  display: inline-block;
  text-align: center;
}
.basic-pagination ul li a:hover {
  background: #096bd8;
  color: #ffffff;
}

.basic-pagination-2 ul li a {
  background: transparent;
  color: #9990b8;
  border: 2px solid #eaebec;
  height: 60px;
  width: 60px;
  line-height: 56px;
}
.basic-pagination-2 ul li a:hover {
  background: #096bd8;
  color: #ffffff;
  border-color: #096bd8;
}
.basic-pagination-2 ul li.active a {
  background: #096bd8;
  color: #ffffff;
  border-color: #096bd8;
}

/* 13.shop */
.shop-service img {
  margin-bottom: 20px;
}
.shop-service h4 {
  font-size: 16px;
  letter-spacing: 2px;
  margin-bottom: 10px;
}
.shop-service p {
  margin-bottom: 0;
  font-size: 16px;
}

.banner-box {
  border-top: 2px solid #f1f7ff;
  padding-top: 50px;
}

.banner img {
  width: 100%;
}

.product__img {
  position: relative;
}
.product:hover .product-action a {
  margin: 0 5px;
  opacity: 1;
  visibility: visible;
}

.product-action {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
}
.product-action a {
  height: 50px;
  display: inline-block;
  width: 50px;
  background: #fff;
  line-height: 50px;
  color: #096bd8;
  margin: 0 8px;
  opacity: 0;
  visibility: hidden;
}
.product-action a:hover {
  background: #096bd8;
  color: #ffffff;
}

.pro-cat {
  margin-bottom: 15px;
  display: block;
}
.pro-cat a {
  color: #096bd8;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 500;
  text-transform: uppercase;
}

.pro-title {
  margin-bottom: 12px;
}
.pro-title a {
  font-size: 24px;
}
.pro-title a:hover {
  color: #096bd8;
}

.price span {
  color: #8e84b8;
  font-size: 14px;
  display: inline-block;
  margin: 0 5px;
}
.price span.old-price {
  color: #b7afd5;
  text-decoration: line-through;
}

.product-showing p {
  margin: 0;
  border: 2px solid #eaedff;
  padding: 17px 30px;
  text-align: center;
  line-height: 1;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .product-showing p {
    padding: 17px 15px;
  }
}

.pro-filter {
  position: relative;
  display: inline-block;
}
@media (max-width: 767px) {
  .pro-filter {
    float: left;
  }
}
.pro-filter select {
  border: 2px solid #eaedff;
  padding: 17px 30px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 1;
  color: #696969;
  appearance: none;
  -moz-appearance: none;
  width: 145px;
  font-weight: 500;
}

.pro-filter::before {
  content: "";
  right: 15px;
  top: 15px;
  position: absolute;
  color: #758799;
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
}

.shop-tab ul li {
  margin-left: 20px;
}
@media (max-width: 767px) {
  .shop-tab ul li {
    margin-left: 10px;
  }
}
.shop-tab ul li a {
  font-size: 16px;
  font-weight: 500;
  color: #696969;
  letter-spacing: 2px;
  padding: 0;
  text-transform: uppercase;
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #f6f6ff;
  line-height: 50px;
  text-align: center;
}
.shop-tab ul li a.active {
  color: white;
  background: #096bd8;
}

.shop-thumb-tab {
  width: 160px;
  float: left;
}
@media (max-width: 767px) {
  .shop-thumb-tab {
    float: none;
    width: 100%;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .shop-thumb-tab ul {
    margin: 0 -5px;
  }
}
.shop-thumb-tab ul li {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .shop-thumb-tab ul li {
    width: 33.33%;
    float: left;
    padding: 0 5px;
  }
}
.shop-thumb-tab ul li a {
  padding: 0;
}
.shop-thumb-tab ul li img {
  width: 100%;
}

.product-details-img {
  margin-left: 180px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .product-details-img {
    margin-left: 0;
  }
}

.product-large-img img {
  width: 100%;
}

.product-details-title p {
  color: #6a667b;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.product-details-title h1 {
  font-size: 40px;
  margin: 0;
  line-height: 1.2;
  letter-spacing: -2px;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .product-details-title h1 {
    font-size: 36px;
  }
}

.details-price span {
  color: #096bd8;
  font-size: 20px;
  font-weight: 300;
  font-family: "cerebri_sansbold";
  margin-left: 0;
  margin-right: 10px;
}

.details-price {
  border-bottom: 2px solid #eaedff;
}

.product-cat span {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}
.product-cat a {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
}
.product-cat a:hover {
  color: #096bd8;
}

.product__img img {
  width: 100%;
}

.product-social a {
  margin-right: 10px;
  background: #f5f5ff;
  height: 50px;
  width: 50px;
  line-height: 48px;
  border-radius: 30px;
  color: #696969;
  display: inline-block;
  text-align: center;
  font-size: 14px;
}
@media (max-width: 767px) {
  .product-social a {
    margin-bottom: 10px;
  }
}
.product-social a:hover {
  background: #096bd8;
  color: #ffffff;
}

.plus-minus {
  display: inline-block;
}
@media (max-width: 767px) {
  .plus-minus {
    display: block;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .plus-minus {
    display: inline-block;
    margin-bottom: 0;
  }
}

.action-btn {
  background: #096bd8;
  padding: 20px 25px;
  border: none;
  margin-left: 15px;
  color: #ffffff;
  display: inline-block;
  border-radius: 4px;
}
.action-btn:hover {
  background: #096bd8;
  color: #ffffff;
}

.product-action-list a {
  float: left;
}
@media (max-width: 767px) {
  .product-action-list a.btn {
    padding: 23px 30px;
    margin-bottom: 7px;
  }
}
@media (max-width: 767px) {
  .product-action-list a.action-btn {
    margin-left: 5px;
    margin-bottom: 7px;
  }
}

.cart-plus-minus input {
  height: 60px;
  width: 100px;
  border-radius: 5px;
  border: 2px solid #eaedff;
  text-align: center;
  -moz-appearance: none;
  appearance: none;
}

.cart-plus-minus {
  display: inline-block;
  position: relative;
  margin-right: 15px;
}

.cart-plus-minus .qtybutton {
  position: absolute;
  top: 15px;
  left: 17px;
  font-size: 20px;
  color: #c4bedd;
  cursor: pointer;
}

.cart-plus-minus .inc {
  left: auto;
  right: 17px;
}

.additional-info .table td, .additional-info .table th {
  padding: 0.75rem 0;
  vertical-align: top;
  border-top: 1px solid #f6f6f6;
  font-weight: 400;
}

.shop-cat a {
  padding: 45px 30px;
  border: 2px solid #eaedff;
  display: block;
}
.shop-cat a i {
  font-size: 100px;
  color: #096bd8;
  margin-bottom: 30px;
  transition: 0.3s;
}
.shop-cat a h4 {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  font-family: "cerebri_sansbold";
  transition: 0.3s;
  letter-spacing: 1px;
}
.shop-cat a:hover {
  background: #096bd8;
  border-color: #096bd8;
}
.shop-cat a:hover i {
  color: #ffffff;
}
.shop-cat a:hover h4 {
  color: #ffffff;
}

.product-list-content .price span:first-child {
  margin-left: 0;
}

.tab-menu li {
  margin: 0 10px;
}
@media (max-width: 767px) {
  .tab-menu li {
    margin-bottom: 20px;
  }
}
.tab-menu li a {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 25px 45px;
  background: #1a1430;
  border-radius: 30px;
  line-height: 1;
  color: #aeabba;
}
.tab-menu li a.active {
  color: #ffffff;
  background: #096bd8;
}

.bakix-details-tab ul {
  border-bottom: 2px solid #e1e1e1;
}
.bakix-details-tab ul li a {
  font-size: 16px;
  font-weight: 500;
  color: #8c8bb5;
  letter-spacing: 2px;
  padding: 0;
  text-transform: capitalize;
  position: relative;
  padding: 0 25px;
}
@media (max-width: 767px) {
  .bakix-details-tab ul li a {
    padding: 0 10px;
  }
}
.bakix-details-tab ul li a.active {
  color: #100d1c;
}
.bakix-details-tab ul li a.active:before {
  position: absolute;
  bottom: -32px;
  left: 0;
  height: 2px;
  width: 100%;
  content: "";
  background: #096bd8;
  transition: 0.3s;
}
@media (max-width: 767px) {
  .bakix-details-tab ul li a.active:before {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .bakix-details-tab ul li a.active:before {
    display: block;
  }
}

/*# sourceMappingURL=main.css.map */
