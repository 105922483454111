/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {
    .map-img img {
        width: 100%;
    }
    .header-2-icon a {
	    margin-right: 0px;
    }
    .management-area {
        padding-bottom: 95px;
    }
    .management-img img {
        width: 100%;
    }
    .menu-2 {
        padding-left: 15px;
        padding-right: 15px;
    }
    .menu-3 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .menu-2 .header-lang {
        margin-right: 15px;
    }
    .menu-2.sticky {
        padding-left: 15px;
        padding-right: 15px;
    }
    .header-top-right {
        margin-left: 0;
    }







}


/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {
    .main-menu nav > ul > li {
        margin-left: 17px;
    }
    .slider-content p {
        margin-right: 0;
    }
    .about-content {
        margin-left: 0;
        margin-top: 0;
    }
    .about-img {
        margin-left: 0;
    }
    .about-text {
        margin-top: 0;
    }
    .video-wrapper {
        margin-left: 0;
        margin-top: 0;
    }
    .single-newsletters {
        padding: 15px;
    }
    .newsletter-form form input {
        width: 327px;
    }
    .newsletter-form button.btn {
        padding: 20px 28px;
    }
    .footer-news-img {
        margin-right: 10px;
    }
    .testimonial-text p {
        margin-right: 0;
    }
    .footer-news {
        padding: 10px;
    }
    .team-text {
        padding: 22px 18px 30px 18px;
    }
    .cta-link {
        margin-left: 15px;
    }
    .testimonial-img {
        margin-left: 0;
    }
    .testimonial-img img{width:100%;}
    .testimonial-active .owl-nav div {
        bottom: -78px;
    }
    .testimonial {
        padding-bottom: 177px;
    }
    .single-blog .blog-text p {
        display: none;
    }
    .blog-meta {
        padding: 13px 10px 13px;
        margin-left: 10px;
        margin-right: 15px;
    }
    .blog-meta span {
        margin-right: 9px;
    }
    .single-blog {
        padding: 32px 14px 14px 0;
    }
    .menu-2 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .menu-3 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .header-2-button {
        margin-right: 0;
    }
    .slider-content-3 {
        padding-top: 40px;
    }
    .slider-height-3 {
        min-height: 700px;
        background-position: left;
    }
    .slider-content-3 h1 {
        font-size: 72px;
    }
    .about-2-img img {
        width: 100%;
    }
    .our-services-area{
        padding-left: 0;
        padding-right: 0;
    }
    .our-services-icon {
        float: none;
        margin-right: 0;
        margin-bottom: 20px;
    }
    .our-services-wrapper {
        text-align: center;
    }
    .our-services-wrapper {
        padding-left:15px;
        padding-right:15px;
    }
    .client-say-text {
        padding: 36px 20px 50px 20px;
    }
    .client-say-content h4 {
        font-size: 18px;
    }
    .progress-bar-text {
        padding: 60px 80px 60px 70px;
    }
    .map-img img {
        width: 100%;
    }
    .footer-wrapper.footer-2-wrapper{
        padding-left: 0;
    }
    .angle-icon {
        display: none;
    }
    .contact-wrapper {
        margin-right: 0;
    }
    .footer-2-wrapper .footer-text p {
        padding-right: 0;
    }
    .section-title{
        padding-left: 0;
        padding-right: 0;
    }
    .about-us-wrapper {
        margin-left: 0;
    }
    .case-text {
        padding: 30px 20px 40px 20px;
    }
    .case-text h3 {
        font-size: 22px;
    }
    .contact-us-info {
        padding: 37px 50px 37px 40px;
    }
    .newsletter-2-bg {
        padding-left: 30px;
        padding-right: 30px;
    }
    .footer-3-icon a {
        margin-bottom: 8px;
    }
    .services-shape-left {
        bottom: 56px;
    }
    .services-shape-right {
        bottom: 56px;
    }
    .cta-content {
        margin-right: 0;
    }
    .ctas-info {
        margin-left: 0;
        padding: 52px 20px 56px 20px;
    }
    .testimonial-2-active .owl-nav div {
        left: -80px;
    }
    .testimonial-2-active .owl-nav div.owl-next {
        left: auto;
        right: -80px;
    }
    .single-testimonial {
        margin-left: 34px;
        margin-right: 34px;
    }
    .frequently-text li {
        padding: 33px 30px 34px 30px;
        margin-right: 0;
    }
    .choose-bg {
        margin-left: 15px;
        margin-right: 15px;
    }
    .about-1-wrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .it-management-wrapper {
        margin-left: 0;
    }
    .management-area {
        padding-bottom: 95px;
    }
    .management-img img {
        width: 100%;
    }
    .our-case-area {
        padding-right: 0;
        padding-left: 0;
    }
    .gallery-layout-bg {
        padding: 75px 30px 84px 30px;
    }
    .gallery-layout-info {
        margin-right: 30px;
    }
    .experience-text p {
        margin-right: 0;
    }
    .single-img img {
        width: 100%;
    }
    .single-team-wrapper {
        margin-right: 0;
        margin-top: 0;
    }
    .golas-wrapper {
        padding: 120px 0 130px 20px;
    }
    .services-2-wrapper {
        padding-right: 0;
    }
    .our-golas-text p {
        margin-right: 0;
    }
    .slider-active button.slick-arrow {
        left: 20px;
    }
    .slider-active button.slick-next {
        left: auto;
        right: 20px;
    }
    .menu-2.sticky {
        padding-left: 30px;
        padding-right: 30px;
    }
    .header-top-right {
        margin-left: 0;
    }
    .header-top-cta-text {
        margin-right: 22px;
    }
    .features-text p {
        padding: 0 28px;
    }
    .about-2-wrapper {
        padding-top: 0;
    }
    .services-tab li a {
        padding: 28px 20px;
    }
    .team-2-img img {
        width: 100%;
    }
    .team-2-wrapper {
        padding: 30px 10px;
    }






}


/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
    .about-img {
        margin-left: 0;
    }
    .team-2-img img {
        width: 100%;
    }
    .menu-2.sticky {
        padding-left: 0;
        padding-right: 0;
    }
    .slider-active button.slick-arrow {
        left: 10px;
    }
    .slider-active button.slick-next {
        left: auto;
        right: 10px;
    }
    .slider-1-height {
        min-height: 600px;
    }
    .about-content {
        margin-left: 0;
        margin-top: 0;
    }
    .slider-content-3 {
        padding-top: 40px;
    }
    .slider-height-3 {
        min-height: 700px;
        background-position: left;
    }
    .cta-button {
        margin-top: 0;
    }
    .project-area {
        padding-left: 40px;
        padding-right: 40px;
    }
    .testimonial-wrapper::before {
        top: 11px;
        font-size: 191px;
    }
    .testimonial-text p {
        margin-right: 0;
    }
    .video-wrapper {
        margin-left: 0;
        margin-top: 0;
    }
    .newsletter-form form input {
        width: 430px;
    }
    .single-newsletters {
        background: #fff;
        padding: 20px 30px;
    }
    .about-text {
        margin-top: 0;
    }
    .header-ph{display: none;}
    .header-en{display: none;}
    .main-menu-area {
        padding: 28px 0 14px 0;
    }
    .slider-content h1 {
        font-size: 60px;
    }
    .slider-content p {
        margin-right: 20px;
    }
    .slider-height {
        min-height: 600px;
    }
    .slider-text > span {
        font-size: 30px;
    }
    .slider-3-height {
        min-height: 600px;
    }
    .features-area {
        margin-top: 130px;
    }
    .features-text p {
        padding: 0 30px;
    }
    .features-wrapper {
        border-right: 0;
    }
    .about-2-wrapper {
        padding-top: 0;
    }
    .services-tab-area {
        padding-bottom: 100px;
    }
    .services-tab li a {
        padding: 18px 12px;
    }
    .services-tap-text h4 {
        font-size: 15px;
    }
    .services-tap-icon i {
        font-size: 20px;
    }
    .work-process-wrapper {
        padding: 74px 30px 66px 30px;
    }
    .counter-bg {
        margin-left: 0;
        margin-top: 0;
    }
    .ctas-info {
        margin-top: 0;
    }
    .about-1-single-text {
        margin-top: 0;
    }
    .about-1-single-text p {
        margin-right: 0;
    }
    .single-blog {
        padding: 32px 26px 20px 26px;
    }
    .footer-wrapper.footer-2-wrapper{
        padding-left: 0;
    }
    .angle-icon {
        display: none;
    }
    .progress-bar-text {
        margin-left: 0;
    }
    .single-newsletter .newsletter-form form input {
        width: 470px;
    }
    .portfolio-menu{
        margin-top: 0;
    }
    .contact-wrapper {
        margin-right: 0;
    }
    .footer-2-wrapper .footer-text p {
        padding-right: 0;
    }
    .about-us-wrapper {
        margin-left: 0;
    }
    .services-3-wrapper {
        margin-top: 0;
    }
    .service-padding {
        padding-right: 15px !important;
    }
    .service-1-padding {
        padding-left: 15px !important;
    }
    .cta-content {
        margin-right: 0;
    }
    .ctas-info {
        margin-left: 0;
        padding: 52px 20px 56px 20px;
    }
    .single-testimonial {
        margin-left: 0;
        margin-right: 0;
        padding: 0 15px 55px 15px;
    }
    .frequently-text li {
        padding: 33px 30px 34px 30px;
        margin-right: 0;
    }
    .frequently-video {
        margin-top: 0;
    }
    .case-studies-content {
        padding: 0;
    }
    .footer-wrapper {
        padding-left: 0;
    }
    .choose-bg {
        margin-left: 15px;
        margin-right: 15px;
    }
    .about-1-wrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .about-us-2-img {
        position: static;
        margin-top: 30px;
    }
    .about-us-2-img img{width:100%;}
    .it-management-wrapper {
        margin-left: 0;
    }
    .our-case-area {
        padding-left: 0;
        padding-right: 0;
    }
    .case-text {
        padding: 30px 20px 40px 20px;
    }
    .gallery-layout-bg {
        padding: 75px 30px 84px 30px;
    }
    .gallery-layout-info {
        margin-right: 20px;
    }
    .case-details-content {
        margin-left: 0;
        margin-top: 0;
    }
    .gallery-layout-info h5 {
        font-size: 21px;
    }
    .case-studies-info p {
        margin-right: 0;
    }
    .experience-text p {
        margin-right: 0;
    }
    .single-img img {
        width: 100%;
    }
    .single-team-wrapper {
        margin-right: 0;
        margin-top: 0;
    }
    .progress-wrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .skill-bg {
        padding: 73px 40px 50px 40px;
    }
    .single-team-wrapper {
        margin-right: 0;
        margin-top: 0;
    }
    .golas-wrapper {
        padding: 120px 0 130px 20px;
    }
    .services-2-wrapper {
        padding-right: 0;
    }
    .our-golas-text p {
        margin-right: 0;
    }
    .menu-4 .mean-container a.meanmenu-reveal {
        border: 1px solid #fff;
        color: #fff;
    }
    .menu-4 .mean-container a.meanmenu-reveal span {
        background: #fff;
    }
    .header-5 .mean-container a.meanmenu-reveal {
        border: 1px solid #fff;
        color: #fff;
        margin-top:-32px;
    }
    .header-5 .mean-container a.meanmenu-reveal span {
        background: #fff;
    }
    .header-area {
        border-bottom: 0;
        padding-top: 33px;
        padding-bottom: 0;
    }
    .product__img a img {
        width: 100%;
    }
    .breadcrumb-area {
        padding-top: 160px;
        padding-bottom: 160px;
    }








}


/* small mobile :320px. */
@media (max-width: 767px) {

    .main-menu-area{
        display: none !important;
    }

    .mob-menu{
        display: block !important;
    }

    .mob-n{
        display: none !important;
    }

    .about-img {
        margin-left: 0;
    }
    .team-2-img img {
        width: 100%;
    }
    .menu-2.sticky {
        padding-left: 0;
        padding-right: 0;
    }
    .about-content {
        margin-left: 0;
        margin-top: 0;
    }
    .cta-button {
        margin-top: 0;
    }
    .project-area {
        padding-left: 40px;
        padding-right: 40px;
    }
    .testimonial-wrapper::before {
        top: 11px;
        font-size: 191px;
    }
    .testimonial-text p {
        margin-right: 0;
    }
    .video-wrapper {
        margin-left: 0;
        margin-top: 0;
    }
    .about-text {
        margin-top: 0;
    }
    .main-menu-area {
        padding: 28px 0 14px 0;
    }
    .slider-content h1 {
        font-size: 40px;
        letter-spacing: 0;
        margin-bottom: 20px;
    }
    .slider-button {
        margin-top: 30px;
    }
    .slider-content p {
        margin-right: 0;
    }
    .slider-height {
        min-height: 600px;
    }
    .slider-1-height {
        min-height: 600px;
    }
    .slider-btn {
        margin-left: 0;
        margin-top: 15px;
    }
    .slider-content-3 {
        padding-top: 40px;
    }
    .slider-height-3 {
        min-height: 600px;
        background-position: left;
    }
    .text-link {
        display: none;
    }
    .about-text h1 {
        font-size: 32px;
    }
    .section-title{
        margin-left: 0;
        margin-right: 0;
    }
    .section-title h1 {
        font-size: 32px;
    }
    .services-wrapper {
        padding: 60px 20px 60px 20px;
    }
    .cta-text h1 {
        font-size: 30px;
    }
    .cta-link {
        display: none;
    }
    .project-area {
        padding-left: 0;
        padding-right: 0;
    }
    .project-text {
        padding: 20px 20px 26px 20px;
        margin: 15px 20px 0 15px;
    }
    .project-text h3 {
        font-size: 23px;
    }
    .testimonial-wrapper::before {
        display: none;
    }
    .testimonial-img {
        margin-left: 0;
        margin-top: 0;
    }
    .testimonial-img img{width:100%;}
    .counter-wrapper {
        text-align: center;
    }
    .contact-bg {
        padding: 30px 30px 30px 30px;
    }
    .newsletter-form form input {
        width: 250px;
    }
    .newsletter-form button.btn {
        margin-left: 0;
        margin-top: 15px;
    }
    .newsletter-text h1 {
        font-size: 30px;
    }
    .footer-bg-bottom {
        padding: 25px 15px;
    }
    .copyright {
        text-align: center;
    }
    .footer-bottem-text {
        text-align: center;
    }
    .single-blog {
        padding: 32px 15px 20px 15px;
    }
    .bolg-top-button {
        margin-top: 0;
    }
    .footer-wrapper.footer-2-wrapper{
        padding-left: 0;
    }
    .angle-icon {
        display: none;
    }
    .about-2-img img{
        width: 100%;
    }
    .our-services-area{
        padding-left: 0;
        padding-right: 0;
    }
    .our-services-icon {
        float: none;
        margin-right: 0;
        margin-bottom: 20px;
    }
    .our-services-wrapper {
        text-align: center;
    }
    .our-services-wrapper {
        padding-left:15px;
        padding-right:15px;
    }
    .portfolio-menu{
        margin-top: 0;
    }
    .portfolio-menu button {
        margin-right: 12px;
    }
    .portfolio-text {
        left: 20px;
        right: 20px;
        padding: 19px 25px 25px 25px;
    }
    .progress-bar-text {
        padding: 60px 20px 60px 20px;
        margin-left: 0;
    }
    .map-img img {
        width: 100%;
    }
    .single-newsletter .newsletter-form form input {
        width: 280px;
    }
    .contact-wrapper {
        margin-right: 0;
    }
    .footer-2-wrapper .footer-text p {
        padding-right: 0;
    }
    .blog-meta {
        padding: 13px 9px 13px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .newsletter-2-bg {
        padding-left: 15px;
        padding-right: 15px;
    }
    .newsletter-2-bg .newsletter-form form input {
        width: 251px;
    }
    .about-us-wrapper {
        margin-left: 0;
    }
    .fact-text h1 {
        font-size: 32px;
    }
    .services-3-wrapper {
        margin-top: 0;
    }
    .service-padding {
        padding-right: 15px !important;
    }
    .service-1-padding {
        padding-left: 15px !important;
    }
    .cta-content {
        margin-right: 0;
    }
    .ctas-info {
        margin-left: 0;
        padding: 52px 20px 56px 20px;
    }
    .cta-content h1 {
        font-size: 32px;
    }
    .cta-2-button a.border-btn {
        margin-left: 0;
        margin-top: 15px;
    }
    .single-testimonial {
        margin-left: 0;
        margin-right: 0;
        padding: 0 15px 55px 0;
    }
    .frequently-text li {
        padding: 33px 30px 34px 30px;
        margin-right: 0;
    }
    .frequently-video {
        margin-top: 0;
    }
    .contact-2-btn .btn {
        padding: 21px 36px;
    }
    .footer-wrapper {
        padding-left: 0;
    }
    .about-1-wrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .choose-bg {
        margin-left: 15px;
        margin-right: 15px;
    }
    .btn-margin {
        margin-left: 0;
        margin-top: 15px;
    }
    .breadcrumb-text > h1 {
        font-size: 40px;
        margin-bottom: 11px;
    }
    .breadcrumb-area {
        padding-top: 150px;
        padding-bottom: 150px;
    }
    .services-icon::before {
        right: 66px;
        top: 10px;
    }
    .services-icon::after {
        left: 63px;
    }
    .about-us-2-img {
        position: static;
        margin-top: 30px;
    }
    .about-us-2-img img{width:100%;}
    .about-us-img {
        padding-right: 0;
    }
    .breadcrumb-menu span {
        font-size: 20px;
    }
    .management-text h1 {
        font-size: 32px;
    }
    .management-img img {
        width: 100%;
    }
    .it-management-wrapper .it-management-text h1 {
        font-size: 32px;
    }
    .it-management-wrapper {
        margin-left: 0;
    }
    .our-case-area {
        padding-left: 0;
        padding-right: 0;
    }
    .case-text {
        padding: 30px 20px 40px 20px;;
    }
    .case-text h3 {
        font-size: 22px;
    }
    .case-text {
        padding: 30px 20px 40px 20px;
    }
    .gallery-layout-bg {
        padding: 75px 30px 70px 30px;
    }
    .gallery-layout-info {
        margin-right: 0;
        margin-bottom: 12px;
    }
    .case-details-content {
        margin-left: 0;
        margin-top: 0;
    }
    .gallery-layout-info h5 {
        font-size: 21px;
    }
    .case-studies-info p {
        margin-right: 0;
    }
    .case-details-text h1 {
        font-size: 35px;
    }
    .experience-text p {
        margin-right: 0;
    }
    .single-img img {
        width: 100%;
    }
    .single-team-wrapper {
        margin-right: 0;
        margin-top: 0;
    }
    .progress-wrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .skill-bg {
        padding: 73px 40px 50px 40px;
    }
    .golas-wrapper {
        padding: 120px 0 130px 20px;
    }
    .services-2-wrapper {
        padding-right: 0;
    }
    .our-golas-text p {
        margin-right: 0;
    }
   .menu-4 .mean-container a.meanmenu-reveal {
        border: 1px solid #fff;
        color: #fff;
    }
    .menu-4 .mean-container a.meanmenu-reveal span {
        background: #fff;
    }
   .header-5 .mean-container a.meanmenu-reveal {
        border: 1px solid #fff;
        color: #fff;
        margin-top:-32px;
    }
    .header-5 .mean-container a.meanmenu-reveal span {
        background: #fff;
    }
    .header-area {
        border-bottom: 0;
        padding-top: 33px;
        padding-bottom: 0;
    }
    .slider-content-4 .border-btn {
        margin-left: 0;
        margin-top: 15px;
    }
    .contact-us-content h1 {
        font-size: 32px;
    }
    .contact-us-wrapper {
        padding: 55px 14px 70px 10px;
    }
    .product-action-list a.btn {
        padding: 21px 30px;
    }
    .blog-inner-img img {
        width: 100%;
    }
    .services-tab-area {
        padding-bottom: 100px;
    }
    .services-tab li a {
        padding: 16px 10px;
        margin-bottom: 10px;
    }
    .services-tap-text h4 {
        font-size: 15px;
    }
    .services-tap-icon i {font-size: 20px;}
    .counter-bg {
        margin-left: 0;
        margin-top: 0;
    }
    .slider-3-height {
        min-height: 740px;
    }
    .features-area {
        margin-top: 130px;
    }
    .slider-text > span {
        font-size: 25px;
    }
    .features-text p {
        padding: 0 18px;
    }
    .about-2-wrapper {
        padding-top: 0;
    }
    .about-1-single-text p {
        margin-right: 0;
    }
    .work-process-wrapper {
        padding: 74px 15px 66px 15px;
    }
    .singles-progress .progress-bar-text {
        padding: 70px 20px 80px 20px;
    }
    .team-section{
        margin-top: 0;
    }
    .ctas-info {
        margin-top: 0;
    }

}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .cta-2-button a.border-btn {
        margin-left: 17px;
        margin-top: 0;
    }
    .btn-margin {
        margin-left: 17px;
        margin-top: 0;
    }
    .services-icon::before {
        right: 171px;
        top: 10px;
    }
    .services-icon::after {
        left: 167px;
    }
    .slider-btn {
        margin-left: 9px;
        margin-top: 0;
    }
    .slider-content-4 .border-btn {
        margin-left: 5px;
        margin-top: 0;
    }
}
